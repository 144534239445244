import React, { useState } from "react";
import { Link } from "react-router-dom";

export const RegFaq = () => {
  const [respostaOne, setRespostaOne] = useState(false);
  const [respostaTwo, setRespostaTwo] = useState(false);
  const [respostaThree, setRespostaThree] = useState(false);
  const [respostaFour, setRespostaFour] = useState(false);
  const [respostaFive, setRespostaFive] = useState(false);
  const [respostaSix, setRespostaSix] = useState(false);
  const [respostaSeven, setRespostaSeven] = useState(false);
  const [respostaEight, setRespostaEight] = useState(false);
  const [respostaNine, setRespostaNine] = useState(false);
  const [respostaTen, setRespostaTen] = useState(false);
  const [respostaEleven, setRespostaEleven] = useState(false);
  const [respostaTwelve, setRespostaTwelve] = useState(false);
  const [respostaThirteen, setRespostaThirteen] = useState(false);
  const [respostaFourteen, setRespostaFourteen] = useState(false);
  const [respostaFifteen, setRespostaFifteen] = useState(false);
  const [respostaSixteen, setRespostaSixteen] = useState(false);

  const handleClick = () => {
    setRespostaOne(!respostaOne);
  };
  const handleClick2 = () => {
    setRespostaTwo(!respostaTwo);
  };
  const handleClick3 = () => {
    setRespostaThree(!respostaThree);
  };
  const handleClick4 = () => {
    setRespostaFour(!respostaFour);
  };
  const handleClick5 = () => {
    setRespostaFive(!respostaFive);
  };
  const handleClick6 = () => {
    setRespostaSix(!respostaSix);
  };
  const handleClick7 = () => {
    setRespostaSeven(!respostaSeven);
  };
  const handleClick8 = () => {
    setRespostaEight(!respostaEight);
  };
  const handleClick9 = () => {
    setRespostaNine(!respostaNine);
  };
  const handleClick10 = () => {
    setRespostaTen(!respostaTen);
  };
  const handleClick11 = () => {
    setRespostaEleven(!respostaEleven);
  };
  const handleClick12 = () => {
    setRespostaTwelve(!respostaTwelve);
  };
  const handleClick13 = () => {
    setRespostaThirteen(!respostaThirteen);
  };
  const handleClick14 = () => {
    setRespostaFourteen(!respostaFourteen);
  };
  const handleClick15 = () => {
    setRespostaFifteen(!respostaFifteen);
  };
  const handleClick16 = () => {
    setRespostaSixteen(!respostaSixteen);
  };

  return (
    <div className="margin-top">
      <div className="page-header-ri-background-question"></div>
      <div className="page-header-ri">
        <h3>Dúvidas Frequentes</h3>
        <span className="subtitle">Registro de Imóveis</span>
      </div>
      <div className="quem-somos container">
        <div className="panel-respostas " id="panel-1">
          <button onClick={handleClick}>
            <p className="panel-title">Onde está localizado o Cartório?</p>
          </button>
          {respostaOne ? (
            <div id="resposta-1" className="panel-collapse">
              <div className="panel-body">
                <p>
                  Avenida Central, Qd. 11 Lt. 23A, Salas 01 a 03, Conjunto
                  Uirapuru, Senador Canedo-GO.
                </p>
              </div>
            </div>
          ) : null}
        </div>
        <div className="panel-respostas" id="panel-2">
          <button onClick={handleClick2}>
            <p className="panel-title">
              Vocês autenticam documentos? Reconhecem firmas? Fazem procuração
              ou escritura?
            </p>
          </button>
          {respostaTwo ? (
            <div id="resposta-2" className="panel-collapse">
              <div className="panel-body">
                <p>
                  Não. Isso tudo é feito apenas em Cartórios de Notas
                  (Tabelionatos de Notas).
                </p>
                <p>
                  Para ver os endereços dos tabelionatos de notas da região{" "}
                  <Link to="/cartorios-proximos/">clique aqui</Link>.
                </p>
                <p>
                  Já os Cartórios de Registro de Imóveis, como o nosso, analisam
                  os documentos que lhes são apresentados e, caso preencham os
                  requisitos legais, praticam atos de registro e averbação, além
                  de expedir certidões e fornecer informações verbais.&nbsp;
                </p>
                <p>
                  Para ver os tipos de cartórios que existem,{" "}
                  <Link to="/cartorios-proximos/">clique aqui</Link>.
                </p>
              </div>
            </div>
          ) : null}
        </div>
        <div className="panel-respostas" id="panel-3">
          <button onClick={handleClick3}>
            <p className="panel-title">
              Quais os valores dos serviços do cartório? É uma porcentagem do
              valor do negócio ou imóvel? Há uma tabela? O valor pode ser
              calculado por telefone?
            </p>
          </button>
          {respostaThree ? (
            <div id="resposta-3" className="panel-collapse">
              <div className="panel-body">
                <p>
                  Conforme determina a Constituição Federal, art. 236, §2º, e
                  Lei Federal n. 10.169/2000, o valor dos emolumentos cartoriais
                  varia de Estado para Estado, em razão da grande disparidade de
                  realidades no país.
                </p>

                <p>
                  Assim, cada Estado possui uma tabela de valores de emolumentos
                  de cartórios. Conforme o art. 1º. da Lei n. 10.169/2000, “o
                  valor fixado para os emolumentos deverá corresponder ao
                  efetivo custo e à adequada e suficiente remuneração dos
                  serviços prestados”, lembrando-se que toda a estrutura do
                  cartório, material e humana, deve ser custeada pessoalmente
                  pelo delegatário do serviço (oficial registrador ou tabelião),
                  o qual suporta todas as responsabilidades civis, trabalhistas,
                  administrativas e penais daí decorrentes.
                </p>

                <p>
                  O Estado de Goiás possui uma das tabelas com menores valores
                  do Brasil. Veja a tabela de Goiás, as de outros Estados e a
                  comparação em “
                  <Link to="/custas-e-emolumentos/">Emolumentos</Link>
                  ”.
                </p>

                <p>
                  A tabela de Goiás também se encontra afixada no mural na
                  Recepção da serventia. Consulte-a. Em caso de dúvidas solicite
                  esclarecimento aos nossos atendentes.
                </p>

                <p>
                  Não podemos proceder a cálculos apenas por informação via
                  telefone, pois precisamos analisar o documento e os nossos
                  arquivos para saber quais atos devem ser praticados e as bases
                  de cálculos a serem utilizadas.
                </p>
              </div>
            </div>
          ) : null}
        </div>
        <div className="panel-respostas" id="panel-4">
          <button onClick={handleClick4}>
            <p className="panel-title">
              Quais atos o Cartório de Registro de Imóveis pratica?
            </p>
          </button>
          {respostaFour ? (
            <div id="resposta-4" className="panel-collapse">
              <div className="panel-body">
                <p>
                  Praticamos atos de registro e averbação, e também realizamos
                  buscas (informando dados sobre pessoas e imóveis constantes em
                  nossos arquivos) e emitimos certidões.
                </p>
              </div>
            </div>
          ) : null}
        </div>
        <div className="panel-respostas" id="panel-5">
          <button onClick={handleClick5}>
            <p className="panel-title">
              Quem pode requerer a prática de atos no cartório?
            </p>
          </button>
          {respostaFive ? (
            <div id="resposta-5" className="panel-collapse">
              <div className="panel-body">
                <p>A resposta pode ser dividida em duas partes:</p>

                <p>
                  <strong>a) Certidões e buscas:</strong> Qualquer pessoa pode
                  solicitar informações sobre pessoas e imóveis constantes em
                  nossos arquivos, e também a emissão de certidões (Lei n.
                  6.015/73, arts. 16 e 17). Uma das finalidades do cartório de
                  Registro de Imóveis é justamente tornar públicas todas as
                  informações trazidas aos seus arquivos.
                </p>

                <p>
                  <strong>b) Registros e averbações:</strong> Quando o documento
                  for um contrato ou uma escritura pública com negócios
                  imobiliários, qualquer pessoa pode apresentar o documento para
                  protocolo. Mas caso se trate de um requerimento de prática de
                  algum outro ato (ex: averbação de construção, de casamento,
                  divórcio, números de documentos, cancelamentos), apenas o
                  proprietário, o comprador, ou alguém que tenha um direito
                  sobre o imóvel (ex: promessa de compra e venda, servidão,
                  usufruto), é que poderá , conforme o caso, requerer uma
                  averbação ou registro.
                </p>

                <p>
                  Essa pessoa pode outorgar procuração para que outrem solicite
                  o registro ou averbação. A procuração pode ser particular,
                  desde que haja o reconhecimento de firma de outorgante (quem
                  outorga a procuração).
                </p>

                <p>
                  Todas as cópias de documentos que instruem o pedido de
                  protocolo devem ser autenticadas.
                </p>
              </div>
            </div>
          ) : null}
        </div>
        <div className="panel-respostas" id="panel-6">
          <button onClick={handleClick6}>
            <p className="panel-title">
              Os requerimentos e declarações devem ter firma reconhecida?
            </p>
          </button>
          {respostaSix ? (
            <div id="resposta-6" className="panel-collapse">
              <div className="panel-body">
                <p>
                  Sim. Todo requerimento ou declaração de particular deve estar
                  com a firma reconhecida do subscritor para que tenha ingresso
                  no Cartório de Registro de Imóveis.
                </p>
              </div>
            </div>
          ) : null}
        </div>
        <div className="panel-respostas" id="panel-7">
          <button onClick={handleClick7}>
            <p className="panel-title">
              Quais os tipos de certidões que posso pedir? Quanto custa?
            </p>
          </button>
          {respostaSeven ? (
            <div id="resposta-7" className="panel-collapse">
              <div className="panel-body">
                <p>
                  Pode-se pedir certidão de qualquer informação constante em
                  nossos arquivos. As certidões mais frequentemente solicitadas
                  são as seguintes:
                </p>

                <p>
                  <strong>a) Certidão de inteiro teor</strong> (também chamada
                  de “certidão de matrícula” ou “certidão de registro”): é a
                  certidão que traz o texto integral da matrícula do imóvel, ou
                  seja, tudo o que consta no histórico do imóvel. Poderão ser
                  visualizados e comprovados todos os atos de registro ou
                  averbação praticados na matrícula. Esta certidão é exigida
                  para a lavratura de escrituras ou contratos bancários, pois é
                  por meio dela que se sabe seguramente a identificação do
                  imóvel e do seu atual proprietário.
                </p>

                <p>
                  <strong>
                    b) Certidão de ônus e ações reais ou pessoas
                    reipersecutórias:
                  </strong>{" "}
                  é a certidão que relata diretamente se há ônus ou ações
                  judiciais constantes na matrícula do imóvel. Esta certidão
                  também é exigida para a lavratura de escrituras ou contratos
                  bancários, pois é por meio dela que se sabe específica e
                  seguramente se há algum ônus incidindo sobre o imóvel (ex:
                  hipoteca, penhora), se há alguma indisponibilidade
                  determinada, ou se há alguma pendência judicial capaz de
                  atingir o futuro adquirente. Pode ser Negativa ou Positiva,
                  conforme o caso.
                </p>

                <p>
                  <strong>c) Certidão de Transcrição:</strong> Quando o imóvel
                  ainda está registrado no sistema anterior (da Transcrição), ou
                  seja, não foi praticado nenhum ato relativo a ele após o ano
                  de 1976 (quando entrou em vigência a Lei n. 6.015/73). Caso o
                  imóvel esteja nesta situação, esta será a certidão que
                  substituirá a certidão de inteiro teor da matrícula mencionada
                  na alínea “a” acima.
                </p>

                <p>
                  <strong>d) Certidão Negativa de Propriedade:</strong> é a
                  certidão que relata se determinada pessoa é proprietária de
                  imóvel registrado na serventia.
                </p>

                <p>
                  <strong>e) Certidão Quinzenária ou Vintenária:</strong> é a
                  certidão que mostra o histórico do imóvel por,
                  respectivamente, quinze ou vinte anos. Por meio desta certidão
                  se sabe toda a cadeia dominial do imóvel e suas vicissitudes
                  durante o período solicitado. Assim, caso neste período o
                  imóvel tenha sido objeto de uma matrícula já encerrada,
                  deve-se expedir certidões de inteiro teor de cada matrícula
                  até se chegar à última, pois assim o interessado terá em suas
                  mãos toda a cadeia dominial daquele imóvel no período
                  solicitado.
                </p>

                <p>
                  <strong>Custos:</strong> O valor de cada certidão varia a
                  partir de R$ 56,54, conforme o seu conteúdo, o que terá que
                  ser analisado pelo Cartório após a solicitação.
                </p>
              </div>
            </div>
          ) : null}
        </div>
        <div className="panel-respostas" id="panel-8">
          <button onClick={handleClick8}>
            <p className="panel-title">
              Qual é o prazo para a entrega da certidão solicitada?
            </p>
          </button>
          {respostaEight ? (
            <div id="resposta-8" className="panel-collapse">
              <div className="panel-body">
                <p>
                  O prazo máximo determinado pela lei é de cinco dias úteis.
                </p>

                <p>
                  Mas em nosso cartório a grande maioria – cerca de 90% – é
                  expedida e entregue imediatamente após a solicitação no guichê
                  de atendimento. Entretanto, há duas exceções: 1 – certidões
                  mais complexas; e 2 – quando são solicitadas muitas certidões.
                  Nesses dois casos excepcionais a entrega não é feita de
                  imediato, mas geralmente ocorre bem antes do prazo máximo de
                  cinco dias úteis.
                </p>
              </div>
            </div>
          ) : null}
        </div>
        <div className="panel-respostas" id="panel-9">
          <button onClick={handleClick9}>
            <p className="panel-title">
              Posso simplesmente obter uma informação verbal sobre alguma pessoa
              ou imóvel?
            </p>
          </button>
          {respostaNine ? (
            <div id="resposta-9" className="panel-collapse">
              <div className="panel-body">
                <p>
                  Sim. Basta indicar o nome e CPF da pessoa ou os dados
                  completos sobre a localização do imóvel (lote xx, quadra xx,
                  bairro xx, matrícula xx ou transcrição xx).
                </p>

                <p>
                  Entretanto, essa informação não tem força de certidão. O único
                  documento escrito emitido pelo Cartório que produz efeitos
                  externos perante particulares, instituições financeiras e
                  órgãos públicos em geral é a certidão.
                </p>
              </div>
            </div>
          ) : null}
        </div>
        <div className="panel-respostas" id="panel-10">
          <button onClick={handleClick10}>
            <p className="panel-title">
              Vocês têm modelos de requerimentos para a prática de atos?
            </p>
          </button>
          {respostaTen ? (
            <div id="resposta-10" className="panel-collapse">
              <div className="panel-body">
                <p>
                  Sim, acesse a página<span>&nbsp;</span>
                  <Link to="/requerimentos-registro/">Requerimentos</Link>.
                </p>

                <p>
                  Veja qual se encaixa na sua necessidade, preencha, imprima,
                  reconheça firma de quem assinar e traga ao cartório juntamente
                  com os documentos necessários ao ato solicitado. Em caso de
                  dúvida quanto a quem pode assinar, veja a resposta à pergunta
                  n. 5.
                </p>
              </div>
            </div>
          ) : null}
        </div>
        <div className="panel-respostas" id="panel-11">
          <button onClick={handleClick11}>
            <p className="panel-title">
              É preciso levar certidões para se registrar uma escritura pública
              ou contrato?
            </p>
          </button>
          {respostaEleven ? (
            <div id="resposta-11" className="panel-collapse ">
              <div className="panel-body">
                <p>
                  Para que o Tabelionato de Notas possa lavrar uma escritura de
                  transmissão de imóvel, eles exigem algumas certidões de quem
                  pretende transmitir o imóvel, tendo em vista as exigências de
                  nossas leis (Lei n. 7.433/85, Decreto 93.240/86, art. Lei n.
                  4.591/64, art. 4º, Lei 8.212/91, Consolidação de Normas da
                  Corregedoria do Eg. TJGO).
                </p>

                <p>
                  Então, em regra, você terá que levar essas certidões ao
                  tabelionato de notas ou ao banco (caso seja um contrato
                  bancário), pois eles formalizarão o negócio (por escritura ou
                  contrato, respectivamente) e precisarão destas certidões.
                </p>

                <p>
                  Não é ao cartório de Registro de Imóveis que você deverá levar
                  essas certidões, em regra.
                </p>

                <p>O objetivo é:</p>

                <p>
                  a) que o adquirente saiba ao máximo qual a situação financeira
                  do vendedor, pois esse poderá estar se desfazendo de bens para
                  se livrar da penhora desses para pagamento de dívidas, e
                  depois quem pode sofrer as consequências é o adquirente;
                </p>

                <p>
                  b) que os credores do vendedor estejam protegidos contra
                  fraude (fraude a credores ou fraude à execução);
                </p>

                <p>
                  c) dar ciência ao comprador da existência de eventuais dívidas{" "}
                  <em>propter rem</em>, ou seja, que estão vinculadas ao imóvel
                  e que passarão para a sua responsabilidade (ex: manutenção de
                  condomínio).
                </p>
              </div>
            </div>
          ) : null}
        </div>
        <div className="panel-respostas" id="panel-12">
          <button onClick={handleClick12}>
            <p className="panel-title">Quais certidões são essas?</p>
          </button>
          {respostaTwelve ? (
            <div id="resposta-12" className="panel-collapse">
              <div className="panel-body">
                <p>
                  <strong>Certidões Fiscais</strong>
                  <span>&nbsp;</span>dos três entes federativos (União, Estado e
                  Município, e, dependendo do caso, certidão de contribuições
                  previdenciárias) e<span>&nbsp;</span>
                  <strong>certidões de feitos ajuizados.&nbsp;</strong>Caso o
                  imóvel integre condomínio (de edifício ou de casas), será
                  necessária também uma<span>&nbsp;</span>
                  <strong>
                    declaração do síndico quanto à situação das contribuições de
                    manutenção do condomínio
                  </strong>
                  <span>&nbsp;</span>(declaração de quitação ou de débito. Segue
                  a lista, para melhor visualização:
                </p>

                <p>
                  <strong>I) Certidões Fiscais:</strong>
                </p>

                <p>
                  <strong>a)</strong> União – trata-se da Certidão conjunta da
                  Receita Federal do Brasil e Procuradoria Geral da Fazenda
                  Nacional. Pode ser expedida pelo site{" "}
                  <Link
                    to="https://www.gov.br/receitafederal/pt-br/"
                    title="Receita"
                    target="_blank"
                  >
                    www.gov.br/receitafederal
                  </Link>
                  .
                </p>

                <p>
                  <strong>b)</strong> Estado – Pode ser expedida pelo site{" "}
                  <Link
                    to="https://www.economia.go.gov.br/"
                    target="_blank"
                    title="Sefaz"
                  >
                    www.economia.go.gov.br
                  </Link>
                  . É expedida pela Secretaria da Fazenda do Estado. Telefone:
                  (62)3216-2704. Fax: (62) 3201-6591.
                </p>

                <p>
                  <strong>c)</strong> Município – Em Senador Canedo se trata do
                  laudo de avaliação, cuja emissão comprova que não há débitos
                  do contribuinte. Pode ser expedido pelo site
                  www.senadorcanedo.go.gov.br
                </p>

                <p>
                  <strong>d)</strong> Contribuições Previdenciárias (esta
                  certidão específica é{" "}
                  <strong>
                    necessária apenas para averbação de construção ou demolição
                  </strong>
                  ): pode ser expedida pelo site{" "}
                  <Link
                    to="https://www.gov.br/receitafederal/pt-br/"
                    title="Receita"
                    target="_blank"
                  >
                    www.gov.br/receitafederal
                  </Link>
                  . INSS – A Lei 8.212/91, art. 47, exige, em regra, para
                  transferências efetuadas por empresas e também para averbação
                  de construção por qualquer pessoa. Pode ser expedida também
                  pela internet, no{" "}
                  <Link
                    to="https://www.gov.br/receitafederal/pt-br"
                    title="Receita"
                    target="_blank"
                  >
                    www.gov.br/receitafederal
                  </Link>
                  .
                </p>

                <p>
                  <strong>
                    II) Comprovantes de pagamento dos Impostos de Transmissão
                  </strong>
                </p>

                <p>
                  Em toda transmissão vai incidir um imposto municipal ou um
                  imposto estadual (vai depender se a transmissão é,
                  respectivamente, onerosa ou gratuita).
                </p>

                <p>
                  Respectivamente, são o ITBI (imposto de transmissão{" "}
                  <em>inter vivos</em>), e o ITCMD (imposto sobre transmissão{" "}
                  <em>causa mortis</em> ou doação).
                </p>

                <p>
                  <strong>a) ITBI (ISTI).</strong> Caso a transmissão do imóvel
                  seja onerosa, ou seja, com pagamento de preço (ex: compra e
                  venda, dação em pagamento, arrematação), o imposto que incide
                  é o ITBI, cobrado pela Prefeitura. É preciso que nesse caso
                  seja comprovado que houve o pagamento de ITBI. Essa
                  comprovação pode se dar perante o Cartório de Notas ou Banco,
                  no momento da lavratura da escritura ou contrato. Ou também a
                  comprovação pode se dar perante o Cartório de Registro de
                  Imóveis, quando do registro da escritura ou contrato. Uma
                  coisa é certa: sem essa comprovação, a escritura ou contrato
                  não poderão ser registrados, sob pena de responsabilidade do
                  Cartório. O pagamento deve se dar perante a Prefeitura, e o
                  comprovante (laudo de avaliação) deve ser levado ao Cartório.
                  Laudo pode ser acessado no site www.senadorcanedo.go.gov.br
                </p>

                <p>
                  Importante: Caso por algum motivo o imposto não incida, seja
                  isento, ou haja imunidade, deve ser levado ao Cartório
                  documento da Prefeitura reconhecendo um desses fatos. O
                  Cartório não pode, por si, dizer se há não incidência, isenção
                  ou imunidade.
                </p>

                <p>
                  <strong>b) ITCMD.</strong> Caso a transmissão do imóvel seja
                  gratuita, ou seja, sem o pagamento de preço, o imposto que
                  incide é o ITCMD, cobrado pelo Estado. Exemplos: Herança,
                  doação, usufruto gratuito. Neste caso o pagamento deve se dar
                  perante a AGENFA (Agência Fazendária do Estado) e deve ser
                  levado o comprovante no Cartório. Sem isso, não se registra a
                  escritura, sob pena de responsabilização do Cartório.
                </p>

                <p>
                  Importante: Caso por algum motivo o imposto não incida, seja
                  isento, ou haja imunidade, deve ser levado ao Cartório
                  documento da Prefeitura reconhecendo um desses fatos. O
                  Cartório não pode, por si, dizer se há não incidência, isenção
                  ou imunidade.
                </p>
              </div>
            </div>
          ) : null}
        </div>
        <div className="panel-respostas" id="panel-13">
          <button onClick={handleClick13}>
            <p className="panel-title">
              Em qual Cartório de Registro de Imóveis devo registrar a minha
              escritura ou contrato? Em qualquer um do meu município?
            </p>
          </button>
          {respostaThirteen ? (
            <div id="resposta-13" className="panel-collapse">
              <div className="panel-body">
                <p>
                  Em cada município cada Cartório de Registro de Imóveis possui
                  sua área de circunscrição própria, onde todos os imóveis que
                  lá estão localizados só podem ser registrados neste cartório.
                </p>

                <p>
                  Então o seu registro não pode se dar em qualquer Cartório.
                  Você deve pesquisar para saber em qual dos cartórios de sua
                  cidade o seu imóvel está localizado.
                </p>

                <p>
                  Caso o cartório “errado” faça o registro por engano, o ato
                  será nulo e estará lhe trazendo grande insegurança jurídica.
                  Isso pode ocorrer por equívoco quando há mudanças de áreas de
                  competência entre cartórios ou a criação de outros cartórios,
                  o que demanda maior cuidado do proprietário para saber em qual
                  cartório o seu imóvel deve ser registrado. No caso de ter
                  havido alteração da área dos cartórios e o seu imóvel estar
                  registrado no “cartório antigo”, será necessário pegar uma
                  certidão nesse “antigo” e levar ao cartório novo para, antes
                  de qualquer ato, abrir nova matrícula do seu imóvel,
                  encerrando-se o registro no cartório anterior.
                </p>
              </div>
            </div>
          ) : null}
        </div>
        <div className="panel-respostas" id="panel-14">
          <button onClick={handleClick14}>
            <p className="panel-title">
              Onde ficam outros cartórios da região? Quais os telefones deles?
            </p>
          </button>
          {respostaFourteen ? (
            <div id="resposta-14" className="panel-collapse">
              <div className="panel-body">
                <p>
                  Telefones e endereços de outros cartórios da região estão na
                  página<span>&nbsp;</span>
                  <Link to="/cartorios-proximos/">
                    Outros Cartórios Próximos
                  </Link>
                  .
                </p>
              </div>
            </div>
          ) : null}
        </div>
        <div className="panel-respostas" id="panel-15">
          <button onClick={handleClick15}>
            <p className="panel-title">
              Qual o prazo para a realização dos atos no Cartório?
            </p>
          </button>
          {respostaFifteen ? (
            <div id="resposta-15" className="panel-collapse">
              <div className="panel-body">
                <p>
                  A lei estabeleceu algumas prioridades e previu prazos máximos
                  diferentes para alguns documentos:
                </p>

                <p>a) Regra Geral: 30 dias;</p>

                <p>b) Contratos do “Minha Casa/Minha Vida”: 15 dias;</p>

                <p>c) Cédulas: 3 dias úteis.</p>

                <p>
                  Estamos trabalhando para sempre praticar os atos e
                  disponibilizar os documentos para retirada em prazos cada vez
                  menores.
                </p>
              </div>
            </div>
          ) : null}
        </div>
        <div className="panel-respostas" id="panel-16">
          <button onClick={handleClick16}>
            <p className="panel-title">
              Como posso acompanhar o andamento do meu protocolo e saber em qual
              dia que estará pronto?
            </p>
          </button>
          {respostaSixteen ? (
            <div id="resposta-16" className="panel-collapse">
              <div className="panel-body">
                <p>
                  Acompanhe pelo página{" "}
                  <Link to="/consulta-protocolo/">Consulta de Protocolos</Link>.
                </p>

                <p>
                  Ao ser protocolado, o seu documento inicia um caminho pelos
                  setores do Cartório, sendo em cada um realizada uma etapa
                  necessária ao ato praticado com sucessivas revisões e
                  análises, para garantir a segurança jurídica do seu ato. Isso
                  é necessário pois o ato praticado pelo Cartório ganha força de
                  presunção de legalidade, gerando importantes efeitos
                  jurídicos, e devem, portanto, preencher os requisitos legais
                  necessários.
                </p>

                <p>
                  O documento inicia seu caminho na fase “Recepção”. Após, o
                  documento vai para o setor de Registro, onde o ato é praticado
                  e o andamento é atualizado para “Análise do Documento”, o qual
                  significa que está sendo analisado e, se não houver exigência
                  nesse momento, provavelmente todos os requisitos para o ato
                  foram cumpridos. Mas ainda serão realizadas mais revisões no
                  caminho do protocolo. Depois, o documento vai ao setor
                  específico de conferência, no qual, além de revisado, é gerado
                  o selo digital que assegurará a autenticidade do ato praticado
                  mediante consulta no site do Tribunal de Justiça ou no na
                  página{" "}
                  <Link
                    to="https://see.tjgo.jus.br/buscas"
                    title="Consulta selos"
                    target="_blank"
                  >
                    Consulta de Selos
                  </Link>
                  . Após revisado, o andamento atualizado é o “Revisão e Selo”.
                  Posteriormente, o documento será novamente conferido pelo
                  escrevente e assinado, sendo levado à digitalização. Após
                  isso, o andamento mostrado será o “Digitalizado”. Após nova
                  revisão é dado o andamento “Pronto para Retirada”, quando o
                  documento está pronto para ser retirado pelo interessado.
                  Neste momento, o apresentante do título receberá uma mensagem
                  SMS e/ou email informando que o documento está pronto para
                  retirada. Por isso é importante que o apresentante informe
                  corretamente um número de telefone celular e email no momento
                  do protocolo.
                </p>

                <p>
                  Caso no andamento haja a fase “Exigência” significa que falta
                  algum requisito para o ato pretendido, devendo o interessado
                  providenciar os documentos faltantes. Neste caso também será
                  enviada mensagem SMS e email ao interessado, informando-o. A
                  fase “Avisado” ocorre quando há alguma pendência da qual o
                  interessado já foi informado ou, ainda, se houve algum
                  incidente durante o protocolo que impede a continuidade do
                  andamento.
                </p>

                <p>
                  Em resumo, os andamentos possíveis são: Recepção &gt; Análise
                  do Documento &gt; Registro &gt; Revisão e Selo &gt;
                  Digitalizado &gt; Finalizado. Também é possível que haja os
                  andamentos “Exigência” e “Avisado”.
                </p>

                <p>
                  O tempo que decorre entre uma fase e outra é variável conforme
                  a complexidade do documento e o volume de serviço no momento.
                </p>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};
