import React from "react";
import image404 from "../imgs/3793096.jpg";

const NotFound = () => {
  return (
    <div className="margin-top">
      <h1 className="mt-3">
        <strong>Página não encontrada</strong>
      </h1>
      <div
        style={{
          width: "100%",
          height: "500px",
          backgroundImage: `URL('${image404}')`,
          backgroundBlendMode: "multiply",
          backgroundColor: "#f8f8f8",
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          backgroundPosition: "center center"
        }}
      ></div>
      <p>Oops! A página não existe ou está em construção!</p>
    </div>
  );
};

export default NotFound;
