import React from "react";

export const PorqueRegistrar = () => {
  return (
    <div className="margin-top">
      <div className="page-header-ri-background-question"></div>
      <div className="page-header-ri">
        <h3>Por que registrar?</h3>
        <span className="subtitle">Registre Títulos e Documentos...</span>
      </div>
      <div className="container mt-3 d-flex flex-column align-items-start quem-somos">
        <h4>Por que registrar?</h4>
        <span className="subtitle">Registre Títulos e Documentos...</span>
        <br />
        <p class='text-justify'>
          <strong>LEVE SEU DOCUMENTO AO RTD</strong> - Através dele é que você
          conquista a segurança jurídica para uma infinidade de tipos de
          documentos. Além disso, você não enfrenta burocracia, filas ou outros
          inconvenientes para preservar, pela eternidade, o valor de qualquer
          documento importante.
        </p>
        <p class='text-justify'>
          <strong>CREDIBILIDADE GARANTIDA</strong> - Qualquer documento
          registrado em Títulos e Documentos ganha publicidade legal. Ou seja, é
          a credibilidade da sua empresa e da sua atuação profissional
          perpetuadas. Do simples registro nasce automaticamente a credibilidade
          da negociação graças à segurança e eficácia que esse registro dá ao
          ato jurídico praticado.
        </p>
        <p class='text-justify'>
          <strong>ESTEJA ATENTO AOS DETALHES</strong> - Autenticidade, segurança
          e eficácia dos atos jurídicos você só consegue com o registro de todo
          e qualquer documento. Reconhecer a firma de um documento não dá a
          ninguém garantias totais. Unicamente com o registro de Títulos e
          Documentos é que o texto ficará perpetuado.
        </p>
        <p class='text-justify'>
          <strong>GANHE TEMPO REGISTRANDO RÁPIDO</strong> - O artigo 130 da Lei
          de Registros Públicos 6.015/73, dá um prazo de 20 dias para registrar
          em Títulos e Documentos. Este é um motivo muito importante para você
          não perder tempo e garantir imediatamente os efeitos jurídicos, desde
          a data que figura no documento.
        </p>
        <p class='text-justify'>
          <strong>O REGISTRO GARANTE A CONSERVAÇÃO</strong> - Só em Títulos e
          Documentos você pode registrar qualquer documento para efeito de
          conservação da data do texto integral (item VII 127 da Lei 6.015/73).
        </p>
        <p class='text-justify'>
          <strong>SEGURANÇA TOTAL E ETERNA</strong> - Registrar qualquer
          documento em Títulos e Documento é dar segurança legal ao negócio
          fechado. Um seguro eterno contra roubo, incêndio, enchente, etc. E
          você paga uma única vez para estar garantido pelo resto da vida!
        </p>
        <p class='text-justify'>
          <strong>TUDO O QUE É IMPORTANTE MERECE REGISTRO</strong> - Se é
          impossível negar a importância de qualquer documento, analise
          cuidadosamente cada um destes itens. Você confirmará as enormes
          vantagens de registrar tudo. A partir daí, esqueça qualquer desculpa
          para deixar de garantir a segurança dos seus documentos e os de sua
          empresa!
        </p>
        <p class='text-justify'>
          <strong>UMA CÓPIA A QUALQUER TEMPO</strong> - Qualquer documento
          registrado em Títulos e Documentos tem segurança permanente. Ou seja,
          você se despreocupa de uma vez. Porque eternamente poderá ter uma
          cópia idêntica e com fé pública. Essa cópia tem o mesmo valor do
          original, também chamada de Certidão de Inteiro Teor.
        </p>
        <p class='text-justify'>
          <strong>VALIDADE CONTRA TERCEIROS</strong> - O art. 129 da Lei de
          Registros Públicos 6.015/73, relaciona os documentos que devem,
          obrigatoriamente, passar pelo Registro de Títulos e Documentos para
          que tenham validade contra terceiros. Entre elas: Contrato de Locação,
          Carta Fiança, Locação de Serviços, Compra e Venda em Prestações,
          Alienação Fiduciária, Documento em Língua Estrangeira, Quitação,
          Recibo, Contrato de Compra e Venda de Automóveis, Cessão de Direitos e
          Créditos, Sub-Rogação.
        </p>{" "}
      </div>
    </div>
  );
};
