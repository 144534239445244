import React, { useState } from "react";

export const ConsultaSelo = () => {
  const [selo, setSelo] = useState();

  const goToSee = (e) => {
    e.preventDefault();
    window.open(`https://see.tjgo.jus.br/buscas?codigo_selo=${selo}`, "_blank");
  };
  return (
    <div className="margin-top no-header-page">
      <div className="container d-flex justify-content-evenly consulta-protocolo">
        <p className="d-flex flex-column">
          <i className="bi bi-search"></i>
          <span>Consulta de Selo Digital no TJGO</span>
        </p>
      </div>
      <form
        onSubmit={goToSee}
        method="get"
        data-request="onSend"
        data-request-flash="true"
        className="form-consulta container"
      >
        <label>
          <strong>Nº do selo:</strong>
        </label>
        <input
          className="form-control"
          type="number"
          placeholder="Digite o número do selo"
          name="selo"
          value={selo}
          onChange={(e) => {
            setSelo(e.target.value);
          }}
        />

        <button className="mt-3 btn btn-primary" type="submit">
          Consultar
        </button>
      </form>
    </div>
  );
};
