import React from "react";
import { Link } from "react-router-dom";

export const TipoCertidoes = () => {
  return (
    <div className="margin-top">
      <div className="page-header-ri-background-certidoes"></div>
      <div className="page-header-ri">
        <h3>Tipos de Certidões</h3>
      </div>
      <div className="container mt-3 d-flex flex-column align-items-start quem-somos">
        <div class="col-md-12 ">
          <h3 className=" d-flex">
            CERTIDÕES DE TÍTULOS E DOCUMENTOS &amp; PESSOA JURÍDICA - INTEIRO
            TEOR
          </h3>
          <p>
            Certidão é a reprodução autêntica do documento original registrado,
            através do processo de armazenamento digital de sua imagem. As
            certidões expedidas pelo Títulos e Documentos têm o mesmo valor de
            prova do original. Por isso, é mais seguro e prático utilizar a
            certidão, guardando o original de um documento importante.O
            andamento da solicitação será feito utilizando-se o número do
            protocolo.
            <br />
            <br />
          </p>
          <h3 className="d-flex">CERTIDÃO SIMPLIFICADA</h3>
          <p>
            É o documento emitido, com informações atualizadas, de atos
            arquivados, contendo as seguintes informações: Denominação/razão
            social, cidade da sede, número de protocolo do registro e
            posteriores averbações com seus respectivos números de protocolos.
            &nbsp;Esta certidão é um instrumento hábil para a confirmação do
            registro e transferências.
          </p>
          <p>
            <Link class="fr-file" to="">
              REQUERIMENTO CERTIDÃO RESUMIDA SIMPLIFICADA
            </Link>
          </p>{" "}
        </div>
      </div>
    </div>
  );
};
