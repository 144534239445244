import React from "react";
import tabela from "../storage/TABELA DE CUSTAS E EMOLUMENTOS 2024.jpg";
import { Link } from "react-router-dom";

export const CustasEmolumentos = () => {
  return (
    <div className="margin-top no-header-page">
      <div className="container mt-3 d-flex flex-column align-items-center ">
        <div class="row  line-left">
          <div class="col-lg-12 col-md-12 col-sm-12 d-flex flex-column ">
            <i class="bi bi-filetype-pdf"></i>
            <p>
              Faça o download da tabela XIV do Regimento de Custas e
              Emolumentos.
            </p>
          </div>
        </div>

        <div class="w-75">
          <div class="click-tabela">
            <Link
              to="https://iuryflores.com.br/riscgo/TABELA DE CUSTAS E EMOLUMENTOS 2024.pdf"
              target="_blank"
            >
              <img
                class="transition img-responsive"
                src={tabela}
                alt="Tabela de emolumentos"
              />
            </Link>
          </div>
          <p className="fs-6-5">
            <strong>
              {" "}
              <i className="bi bi-cloud-arrow-down"></i>
            </strong>{" "}
            Clique na imagem para baixar.
          </p>
        </div>
        <p>
          <br />
        </p>
      </div>
    </div>
  );
};
