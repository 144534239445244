import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export const ConsultaProtocolo = () => {
  const [consulta, setConsulta] = useState("protocolo");

  const [selected, setSelected] = useState("protocolo");

  const [protocolo, setProtocolo] = useState("");
  const [senha, setSenha] = useState("");

  const [message, setMessage] = useState("");

  const [dataAPI, setDataAPI] = useState("");

  const getProtocolo = (protocolo, senha, consulta) => {
    setConsulta("protocolo");
    setSelected("protocolo");
    setDataAPI(null);
    setSenha("");
  };
  const getCertidao = () => {
    setConsulta("certidao");
    setSelected("certidao");
    setDataAPI(null);
    setSenha("");
  };
  const handleClink = (e) => {
    e.preventDefault();
    if (protocolo !== "" || senha !== "") {
      fetch(
        `https://api-risenadorcanedo-go.sistemaasgard.com.br/api/public/site/acompanhamento/${consulta}/${protocolo}/${senha}`
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.message === "Senha inválida") {
            setMessage(data.message);
          } else {
            console.log(data);
            setDataAPI(data);
          }

        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    } else {
      setMessage("Necessário informar número do protocolo/pedido e senha!");
    }
  };
  useEffect(() => {
    setTimeout(() => {
      setMessage(null);
    }, 50000);
  });

  return (
    <div className="margin-top no-header-page">
      <div className="container d-flex justify-content-around consulta-protocolo">
        <div
          onClick={getProtocolo}
          className={`${selected === "certidao" ? "consulta-no-selected" : null
            } d-flex flex-column clickable`}
        >
          <i className="bi bi-search"></i>
          <span>Consulta de Protocolo</span>
        </div>
        <div
          onClick={getCertidao}
          className={`${selected === "protocolo" ? "consulta-no-selected" : null
            } d-flex flex-column clickable`}
        >
          <i className="bi bi-file-earmark-text-fill"></i>
          <span>Consulta de Certidão</span>
        </div>
      </div>

      <form
        onSubmit={handleClink}
        method="get"
        data-request="onSend"
        data-request-flash="true"
        className="form-consulta container"
      >
        <label>
          <strong>
            Nº {consulta.toUpperCase().slice(0, 1) + consulta.slice(1)}:
          </strong>
        </label>
        <input
          className="form-control"
          type="number"
          placeholder="Protocolo"
          name="protocolo"
          value={protocolo}
          onChange={(e) => {
            setProtocolo(e.target.value);
          }}
        />
        <label>
          <strong>Senha:</strong>
        </label>
        <input
          className="form-control"
          type="password"
          placeholder="Senha"
          autoComplete="password"
          name="senha"
          value={senha}
          onChange={(e) => {
            setSenha(e.target.value);
          }}
        />
        <button className="mt-3 btn btn-primary" type="submit">
          Consultar
        </button>

        {message ? (
          <div className="alert alert-danger mt-3 ">{message}</div>
        ) : null}
      </form>
      {dataAPI ? (
        <div className="result-consulta">
          <h4>Acompanhamento Online</h4>
          <table border="1" className="table table-bordered mt-3">
            <tr>
              <th width="30%">
                <b>
                  Situação do Processo <br />
                  (Etapa Atual)
                </b>
              </th>
              <td>{dataAPI.etapa}</td>
            </tr>
            <tr>
              <th width="30%">
                <b>
                  {selected === "certidao"
                    ? "Pedido de Certidão"
                    : selected.toUpperCase().slice(0, 1) + selected.slice(1)}
                </b>
              </th>
              <td>{dataAPI.codigo}</td>
            </tr>
            <tr>
              <th width="30%">
                <b>Cadastro </b>
              </th>
              <td>{dataAPI.cadastro}</td>
            </tr>
            <tr>
              <th width="30%">
                <b>Interessado </b>
              </th>
              <td>{dataAPI.interessado}</td>
            </tr>
            <tr>
              <th width="30%">
                <b>Solicitante </b>
              </th>
              <td>{dataAPI.solicitante}</td>
            </tr>
            <tr>
              <th width="30%">
                <b>Valor total do protocolo </b>
              </th>
              <td>R$ {dataAPI.total}</td>
            </tr>
            <tr>
              <th width="30%">
                <b>Depósito prévio </b>
              </th>
              <td>R$ {dataAPI.entrada}</td>
            </tr>
            {dataAPI.exigencias.length > 0 &&
              <tr>
                <th width='30%'>
                  <b>Exigências</b>
                </th>
                <td className="exigencias">
                  {dataAPI.exigencias.map((exigencia, index) => {
                    return <Link key={index} to={`https://api-risenadorcanedo-go.sistemaasgard.com.br/api/public/site/exigencias/${exigencia.id}/${senha}`}>
                      {exigencia.data} <i class="bi bi-box-arrow-up-right mx-1"></i><br />
                    </Link>
                  })}
                </td>
              </tr>
            }
          </table>
        </div>
      ) : null}
    </div>
  );
};
