import React from "react";

export const PrazosLegais = () => {
  return (
    <div className="margin-top">
      <div className="page-header-ri-background-prazos"></div>
      <div className="page-header-ri">
        <h3>Prazos Legais</h3>
      </div>
      <div className="container d-flex flex-column fs-6-5 quem-somos">
        <p style={{ textIndent: "40px", textAlign: "justify" }}>
          No Registro de Imóveis, Títulos e Documentos e Civil de Pessoas
          Jurídicas de Senador Canedo, seguimos rigorosamente os prazos
          estabelecidos pela legislação vigente, garantindo transparência e
          eficiência em nossos serviços. Os prazos para os diversos tipos de
          serviços estão respaldados pela Lei nº 6.015/73, e pela Lei nº 14.382,
          de 27 de junho de 2022, que regula especificamente o Registro de
          Imóveis.
        </p>
        <div className="d-flex flex-column align-items-center">
          <div className="w-100 d-flex flex-column align-items-start mt-3">
            <h5>Protocolos</h5>
            <table className="table border table-prazos w-100">
              <thead>
                <tr>
                  <th>Descrição da Natureza</th>
                  <th>Prazo Previsto em Lei</th>
                </tr>
              </thead>
              <tbody>
                {/* Dados dos protocolos */}
                <tr>
                  <td>
                    COMPRA E VENDA SEM CLÁUSULAS ESPECIAIS, CONSTRUÇÃO,
                    CANCELAMENTO DE GARANTIA
                  </td>
                  <td>05 dias úteis (§1º do art. 188 da Lei 6.015/73)</td>
                </tr>
                <tr>
                  <td>CÉDULA RURAL, COMERCIAL OU INDUSTRIAL</td>
                  <td>03 dias úteis</td>
                </tr>
                <tr>
                  <td>DEMAIS AVERBAÇÕES E REGISTROS</td>
                  <td>10 dias úteis</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="w-100 d-flex flex-column align-items-start mt-3">
            {/*PRENOTAÇÕES DE TÍTULOS*/}
            <h5>Prenotação dos Títulos</h5>
            <table className="table border table-prazos w-100">
              <thead>
                <tr>
                  <th>Descrição da Natureza</th>
                  <th>Prazo Previsto em Lei</th>
                </tr>
              </thead>
              <tbody>
                {/* Dados dos protocolos */}
                <tr>
                  <td>PRENOTAÇÃO DOS TÍTULOS</td>
                  <td>
                    Prazo de validade do protocolo: 20 dias úteis (artigo 205 da
                    Lei 6.015/73)
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          {/* Certidões */}
          <div className="w-100 d-flex flex-column align-items-start mt-3">
            <h5>Certidões</h5>
            <table className="table border table-prazos w-100">
              <thead>
                <tr>
                  <th>Descrição da Natureza</th>
                  <th>Prazo Previsto em Lei</th>
                </tr>
              </thead>
              <tbody>
                {/* Dados das certidões */}
                <tr>
                  <td>
                    CERTIDÃO DE INTEIRO TEOR DA MATRÍCULA OU DO LIVRO AUXILIAR
                  </td>
                  <td>04 horas úteis, requerida em horário de expediente</td>
                </tr>
                <tr>
                  <td>CERTIDÃO DA SITUAÇÃO JURÍDICA ATUALIZADA DO IMÓVEL</td>
                  <td>01 dia útil</td>
                </tr>
                <tr>
                  <td>CERTIDÃO DE TRANSCRIÇÃO E DEMAIS CASOS</td>
                  <td>05 dias úteis</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
