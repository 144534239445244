import React from "react";
import { Link } from "react-router-dom";

export const ServicosOnline = () => {
  return (
    <div className="servicos ">
      <div className="servicos-top container">
        <i className="bi bi-pc-display"></i>{" "}
        <h5 className="text-wrap"> Serviços Online</h5>
      </div>
      <div className="servicos-div ">
        <div className="w-100 d-flex">
          <Link
            className="link "
            to="consulta-protocolo"
          >
            <i className="bi bi-search"></i>Consulta de protocolo
          </Link>
          <Link
            className="link "
            to="https://registradores.onr.org.br/" target="_blank"
          >
            <i className="bi bi-file-medical-fill"></i>Pedido de certidão online (SAEC)
          </Link>
          <Link
            className="link "
            to="pesquisa-satisfacao"
          >
            <i className="bi bi-emoji-smile-fill"></i>Pesquisa de satisfação
          </Link>
          <Link className="link" to="consulta-selo">
            <i className="bi bi-postage-fill"></i>Consulta de selo no TJGO
          </Link>
        </div>
      </div>
    </div>
  );
};
