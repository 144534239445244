import React from "react";

export const OqueCertidao = () => {
  return (
    <div className="margin-top">
      <div className="page-header-ri-background-question"></div>
      <div className="page-header-ri">
        <h3>O que é uma Certidão?</h3>
      </div>
      <div className="container mt-3 d-flex flex-column align-items-start quem-somos">
        <p>
          Certidão, vem do latim <em>certitudine</em> que significa certeza. A
          certidão é um ato escrito fornecido pelo registrador afirmando ou
          certificando a existência ou não de ato ou fato constantes em
          registros sob sua guarda.
        </p>
        <section>
          <p>
            O poder de certificar é atribuição dada aos notários registradores
            pelo Poder Público, através da Lei 6015/1973 e ou 9492/1997, afim de
            assegurar, resguardar, juridicamente determinada situação relevante.
          </p>

          <blockquote>
            <h3>O Registrador Pacheco Ribeiro define certidão como</h3>“...
            <em>
              ato administrativo enunciativo e consiste em trasladar para o
              documento a ser entregue ao requerente o que consta dos arquivos
              do serviço público. Pode ser extraída de autos de processos
              administrativos ou judiciais, livros ou documentos do acervo de
              qualquer repartição pública. Aos serviços notariais e de registro,
              como serviços públicos que são, incumbe a expedição de certidões
              como uma de suas atividades típicas.”
            </em>
          </blockquote>

          <p>
            A certidão deve ser clara, redigida de modo a informar com segurança
            os registros que lhe digam respeito. Constitui atestado público
            expedido pela autoridade competente, sob forma de declaração escrita
            que se presume verdadeira. Não é a opinião do Oficial sobre
            determinado ato jurídico, mas a reprodução extraída de assentamentos
            sob sua responsabilidade, mediante enunciado escrito ou cópia
            reprográfica autenticada.
          </p>

          <blockquote>
            <h2>Legislação:</h2>O artigo 19, da Lei 6015 de 1973, prescreve que
            deverá ser lavrada a certidão em inteiro teor, resumo, ou em
            relatório, devendo ser devidamente autenticada pelo oficial ou
            substitutos legais, no prazo máximo de 5 (cinco) dias úteis.
            <br />
            Em especial nas certidões de protesto, de acordo com o artigo 27, da
            Lei 9492 de 1997, as certidões abrangerão os 5 (cinco) anos
            anteriores, contados da data do pedido da respectiva certidão, salvo
            quando se referir a protesto específico.
            <br />
            Nestas Certidões não poderão constar, registros cujos cancelamentos
            tiverem sido averbados, salvo se por requerimento escrito do próprio
            devedor ou por ordem judicial.
          </blockquote>
        </section>{" "}
      </div>
    </div>
  );
};
