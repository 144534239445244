import React from "react";

export const QuemSomos = () => {
  return (
    <div className="col-md-12  margin-top fs-6">
      <div className="page-header-ri-background"></div>
      <div className="page-header-ri">
        <h3>Quem somos</h3>
        <span className="">Conheça mais sobre a nossa serventia.</span>
      </div>
      <div className="container mt-3 d-flex flex-column align-items-start quem-somos">
        <h4>Quem é o Oficial Registrador?</h4>
        <p>
          Nome: <strong>Túlio Sobral Martins e Rocha.</strong>
        </p>
        <p>Email: contato@cartorioritdsenadorcanedo.com.br</p>
        <p className="text-justify">
          Aprovado em alguns concursos públicos, ocupou os seguintes cargos
          públicos, desde 1994: Técnico de Finanças e Controle do Ministério da
          Fazenda,Técnico Judiciário do TJDFT, Analista Judiciário do TJDFT,
          Procurador da Fazenda Nacional, Oficial de Registro no Estado do Rio
          de Janeiro. Entre 2009 a 2014, foi Tabelião de Protesto no Estado do
          Acre.
        </p>
        <p className="d-flex flex-column">
          <span>– Pós-graduado em Direito Tributário;</span>
          <span>– Pós-graduado em Direito Econômico e das Empresas;</span>
          <span>– Mestrando em Desenvolvimento Regional; e</span>
          <span>
            – Autor do Livro: Registro Civil das Pessoas Naturais Anotado
          </span>
        </p>

        <h4>Missão</h4>
        <p>
          Garantir a segurança jurídica e a publicidade dos atos praticados em
          respeito à legislação e aos usuários.
        </p>
        <h4>Visão</h4>
        <p>
          Ser constantemente reconhecido pelos usuários por nossa excelência na
          prestação de serviços.
        </p>
        <h4>Valores</h4>
        <p className="d-flex flex-column">
          <span>
            1 -<strong> Segurança Jurídica: na prestação do serviço</strong>
          </span>
          <span>
            2 -
            <strong>
              {" "}
              Ética: agir com honestidade, integridade e transparência nas
              relações estabelecidas;
            </strong>
          </span>
          <span>
            3 -
            <strong>
              {" "}
              Comprometimento: com o trabalho em equipe e com os usuários;
            </strong>
          </span>
          <span>
            4 -{" "}
            <strong> Agilidade e eficiência: na realização dos atos;</strong>
          </span>
          <span>
            5 -<strong> Respeito: no relacionamento com as pessoas</strong>.
          </span>
        </p>
        <h4>Política da Qualidade</h4>
        <p className="text-justify">
          A Política da Qualidade visa garantir a exelência dos serviços
          prestados, priorizando a satisfação do cliente. Através de um
          atendimento humanizado, ágil e eficiente, buscando entender as
          necessidades dos clientes e superar suas expectativas.
          Comprometemo-nos em garantir a segurança jurídica nos atos registrais,
          cumprindo todas as normas e regulamentos aplicáveis. Além disso,
          oferecemos orientações claras e acessíveis para todas as partes
          interessadas. Essa política serve como um guia, sendo constantemente
          revisada e aprimorada par alcançar a plena satisfação dos clientes e
          buscando a melhoria contínua.
        </p>
      </div>
    </div>
  );
};
