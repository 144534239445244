import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import loadingGif from "../imgs/loading-state.gif";

import { MsgError, MsgSucess } from "../components/Shared";

import api from "../utils/api.utils.js";

export const Ouvidoria = ({ message, setMessage }) => {
  const navigate = useNavigate();

  const [tipo, setTipo] = useState("");
  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [assunto, setAssunto] = useState("");
  const [mensagem, setMensagem] = useState("");

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const showMessage = (error) => {
    setError(error);
    setTimeout(() => {
      setError("");
    }, 6000);
  };
  useEffect(() => {
    setTimeout(() => {
      setMessage(null);
    }, 60000);
  }, [message, setMessage]);

  //HANDLE SUBMIT
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (tipo !== "Denúncia*") {
      if (nome && email && assunto && mensagem && tipo) {
        try {
          setMessage(null);
          setLoading(true);
          setError(null);
          await api.sendFaleConosco({
            nome,
            email,
            phone,
            assunto,
            mensagem,
            tipo,
          });

          setMessage("Obrigado! Sua mensagem foi enviada!");
          setLoading(false);
          setNome("");
          setEmail("");
          setPhone("");
          setAssunto("");
          setMensagem("");
          navigate("/ouvidoria/");
        } catch (error) {
          showMessage(error);
          
        }
      } else {
        setError(
          "Campo nome, email, assunto, tipo de manifestação e mensagem são obrigatórios!"
        );
      }
    } else {
      if (mensagem && tipo) {
        try {
          setMessage(null);
          setLoading(true);
          setError(null);
          await api.sendFaleConosco({
            nome,
            email,
            phone,
            assunto,
            mensagem,
            tipo,
          });

          setMessage("Obrigado! Sua mensagem foi enviada!");
          setLoading(false);
          setNome("");
          setEmail("");
          setPhone("");
          setAssunto("");
          setMensagem("");
          navigate("/ouvidoria/");
        } catch (error) {
          showMessage(error);
        }
      } else {
        setError("Campo tipo de manifestação e mensagem são obrigatórios!");
      }
    }
  };

  return (
    <div className="margin-top">
      <div className="page-header-ri-background-fale-conosco"></div>
      <div className="page-header-ri">
        <h3>Canal de Ouvidoria</h3>
      </div>
      <div className="container d-flex flex-column fs-6-5 quem-somos">
        <form
          className="nobottommargin"
          id="contactform"
          name="contactform"
          onSubmit={handleSubmit}
        >
          <p>
            Seja bem-vindo ao nosso Canal de Ouvidoria, criado especialmente
            para atender suas necessidades e esclarecer suas dúvidas. Estamos
            aqui para proporcionar um atendimento transparente, eficiente e
            ético.
          </p>
          <p>
            Para falar conosco e compartilhar suas considerações, utilize um dos
            seguintes meios de comunicação:
          </p>
          <p className="d-flex flex-column">
            <span>
              <strong>E-mail:</strong> contato@cartorioritdsenadorcanedo.com.br
            </span>
            <span>
              <strong>Telefone:</strong> (62) 99150-6886, de segunda à
              sexta-feira, das 08 às 17 horas (exceto sábados, domingos e
              feriados).
            </span>
            <span>
              <strong>Formulário eletrônico </strong> abaixo.
            </span>
          </p>

          <div className="form-group mt-3 d-flex flex-column">
            <div className="w-100 d-flex border-bottom">
              <strong>Tipo de manifestação:</strong>
            </div>
            <div className="d-flex justify-content-around mt-3 border-bottom mb-3 fale-conosco-opcoes">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault2"
                  value="Dúvida"
                  checked={tipo === "Dúvida"}
                  onChange={(e) => setTipo(e.target.value)}
                />
                <label className="form-check-label" htmlFor="flexRadioDefault2">
                  Dúvida
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault1"
                  value="Elogio/Sugestão"
                  checked={tipo === "Elogio/Sugestão"}
                  onChange={(e) => setTipo(e.target.value)}
                />
                <label className="form-check-label" htmlFor="flexRadioDefault1">
                  Elogio/Sugestão
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault1"
                  value="Crítica/Reclamação"
                  checked={tipo === "Crítica/Reclamação"}
                  onChange={(e) => setTipo(e.target.value)}
                />
                <label className="form-check-label" htmlFor="flexRadioDefault1">
                  Crítica/Reclamação
                </label>
              </div>
              <div className="form-check mb-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault1"
                  value="Denúncia*"
                  checked={tipo === "Denúncia*"}
                  onChange={(e) => setTipo(e.target.value)}
                />
                <label className="form-check-label" htmlFor="flexRadioDefault1">
                  Denúncia*
                </label>
              </div>
            </div>
          </div>
          <div className="form-group d-flex flex-column align-items-start ">
            <label htmlFor="template-contactform-name ">
              Nome {tipo !== "Denúncia*" ? <small>*</small> : null}
            </label>
            <input
              type="text"
              id="template-contactform-name"
              name="name"
              className="form-control"
              value={nome}
              onChange={(e) => setNome(e.target.value)}
            />
          </div>

          <div className="form-group d-flex flex-column align-items-start">
            <label htmlFor="template-contactform-email">
              E-mail {tipo !== "Denúncia*" ? <small>*</small> : null}
            </label>
            <input
              type="email"
              id="template-contactform-email"
              name="email"
              className="form-control"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          <div className="form-group d-flex flex-column align-items-start">
            <label htmlFor="phone">Telefone</label>
            <input
              type="text"
              id="phone"
              name="phone"
              className="form-control"
              maxLength="15"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </div>

          <div className="form-group d-flex flex-column align-items-start">
            <label htmlFor="template-contactform-subject">
              Assunto {tipo !== "Denúncia*" ? <small>*</small> : null}
            </label>
            <input
              type="text"
              id="template-contactform-subject"
              name="subject"
              className="form-control"
              value={assunto}
              onChange={(e) => setAssunto(e.target.value)}
            />
          </div>

          <div className="form-group d-flex flex-column align-items-start">
            <label htmlFor="template-contactform-message">
              Mensagem <small>*</small>
            </label>
            <textarea
              className="form-control"
              id="template-contactform-message"
              name="message"
              rows="6"
              cols="30"
              value={mensagem}
              onChange={(e) => setMensagem(e.target.value)}
            ></textarea>
          </div>

          <div className="form-group d-flex flex-column align-items-start mt-3">
            <div className="form-group mt-3 ">
              {!loading ? (
                <button type="submit" className="btn btn-primary">
                  <i className="fa fa-rocket"></i> ENVIAR
                </button>
              ) : (
                <div>
                  <img
                    style={{ width: "40px" }}
                    src={loadingGif}
                    alt="Loading gif"
                  />
                  Enviando...
                </div>
              )}
            </div>
            <div className="mt-3">
              {error && <MsgError id="erroID">{error}</MsgError>}
              {message && <MsgSucess id="messageID">{message}</MsgSucess>}
            </div>
            <blockquote className="mt-3">
              <p className="fs-6 text-justify">
                {" "}
                *Este cartório orienta e conduz sua equipe com base na Lei
                12.846/2013, conhecida como a Lei Anticorrupção, portanto, se
                houver alguma evidência e/ou conhecimento de conduta ilícita por
                parte de nossos colaboradores{" "}
                <strong>DENUNCIE ANONIMAMENTE</strong>. Toda denúncia será
                investigada e medidas serão tomadas, pois não toleramos atuação
                profissional em desacordo com a legislação vigente.
              </p>
            </blockquote>
          </div>
        </form>
      </div>
    </div>
  );
};
