import axios from "axios";

class Api {
  constructor() {
    this.api = axios.create({
      baseURL: "https://mailserver.1rigo.com/",
      /*baseURL: "http://localhost:7003/",*/
    });
    this.api.interceptors.request.use(
      (config) => {
        const token = sessionStorage.getItem("token");
        if (token) {
          config.headers = {
            Authorization: `Bearer ${token}`,
          };
        }
        return config;
      },
      (error) => {
        console.log(error);
      }
    );
    this.api.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response.status === 401) {
          sessionStorage.removeItem("token");
          window.location = "/pesquisa-satisfacao/";
        }
        throw error;
      }
    );
  }
  send = async (sendInfo) => {
    try {
      const { data } = await this.api.post("/send", sendInfo);
      sessionStorage.setItem("token", data.token);
      return data;
    } catch (error) {
      throw error.response.data.msg;
    }
  };
  sendFaleConosco = async (sendInfo) => {
    try {
      const { data } = await this.api.post("/send/faleconosco/", sendInfo);
      sessionStorage.setItem("token", data.token);
      return data;
    } catch (error) {
      throw error.response.data.msg;
    }
  };
  sendLGPD = async (sendInfo) => {
    try {
      const { data } = await this.api.post("/send/lgpd/", sendInfo);
      sessionStorage.setItem("token", data.token);
      return data;
    } catch (error) {
      throw error.response.data.msg;
    }
  };
}
/* eslint-disable-next-line*/
export default new Api();
