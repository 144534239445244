import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import api from "../utils/api.utils";

import loadingGif from "../imgs/loading-state.gif";

import { MsgError, MsgSucess } from "../components/Shared";

export const PesquisaSatisfacao = ({ message, setMessage }) => {
  const navigate = useNavigate();

  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");

  const [atendimento, setAtendimento] = useState("");
  const [atendente, setAtendente] = useState("");

  const [segmento, setSegmento] = useState("");
  const [outroSeg, setOutroSeg] = useState("");

  /*SATISFAÇÃO*/
  const [educacao, setEducacao] = useState("");
  const [clareza, setClareza] = useState("");
  const [organizacao, setOrganizacao] = useState("");
  const [rapidez, setRapidez] = useState("");
  const [outrosRI, setOutrosRI] = useState("");

  const [notaAtendente, setNotaAtendente] = useState("");
  const [notaCartorio, setNotaCartorio] = useState("");

  const [elogio, setElogio] = useState("");
  const [sugestao, setSugestao] = useState("");

  const [error, setError] = useState("");
  //const [message, setMessage] = useState("");

  const [loading, setLoading] = useState(false);

  const showMessage = (error) => {
    setError(error);
    setTimeout(() => {
      setError("");
    }, 6000);
  };
  useEffect(() => {
    setTimeout(() => {
      setMessage(null);
    }, 10000);
  }, [message, setMessage]);

  //HANDLE SUBMIT
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (nome && email) {
      try {
        setMessage(null);
        setLoading(true);
        setError(null);
        await api.send({
          nome,
          email,
          atendimento,
          atendente,
          segmento,
          outroSeg,
          educacao,
          clareza,
          organizacao,
          rapidez,
          outrosRI,
          notaAtendente,
          notaCartorio,
          elogio,
          sugestao,
        });

        setMessage("Obrigado por realizar a pesquisa de satisfação!");
        setLoading(false);
        setNome("");
        setEmail("");
        setAtendimento("");
        setAtendente("");
        setSegmento("");
        setOutroSeg("");
        setEducacao("");
        setClareza("");
        setOrganizacao("");
        setRapidez("");
        setOutrosRI("");
        setNotaAtendente("");
        setNotaCartorio("");
        setElogio("");
        setSugestao("");
        navigate("/pesquisa-satisfacao/");
      } catch (error) {
        showMessage(error);
      }
    } else {
      console.error({ error });
      setError("Campo nome e email são obrigatórios!");
    }
  };

  return (
    <div className="margin-top">
      <div className="page-header-ri-background-pesquisa-satisfacao"></div>
      <div className="page-header-ri pesquisa">
        <h3>Pesquisa de Satisfação</h3>
      </div>
      <div className=" container form-pesquisa">
        <header>
          <p>
            Estamos realizando uma pesquisa para entender sua percepção sobre os
            serviços que prestamos. Contamos com sua sinceridade. Obrigado.
          </p>{" "}
        </header>
        <form
          className="form-data  d-flex flex-column fs-6-5"
          data-request="onSend"
          data-request-flash="true"
          data-request-update="'psSatisfacao::result' : '#content-form'"
          id="form-ps"
          onSubmit={handleSubmit}
        >
          <div className="row d-flex ">
            <div className="col-md-12 d-flex flex-column align-items-start">
              <label htmlFor="Cliente">
                <strong> Nome </strong>
                <small className="form-text text-muted">*</small>
              </label>
              <input
                type="text"
                name="Pesquisa[Cliente]"
                id="Cliente"
                className="form-control"
                placeholder="Seu nome"
                value={nome}
                onChange={(e) => setNome(e.target.value)}
              />
            </div>
          </div>
          <div className="row d-flex ">
            <div className="col-md-12  d-flex flex-column align-items-start">
              <label htmlFor="Email">
                <strong> Email </strong>
                <small className="form-text text-muted">*</small>
              </label>

              <input
                type="text"
                name="Pesquisa[Email]"
                id="Email"
                className="form-control"
                placeholder="E-mail"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </div>
          <div className="row d-flex">
            <div className=" d-flex flex-column align-items-start">
              <label htmlFor="DataAtendimento">
                <strong> Lembra o dia que foi atendido ?</strong>{" "}
                <small className="form-text text-muted">Opcional</small>
              </label>
              <div className="input-group date" id="datetimepicker2">
                <input
                  type="date"
                  name="Pesquisa[DataAtendimento]"
                  id="DataAtendimento"
                  className="form-control date"
                  value={atendimento}
                  onChange={(e) => setAtendimento(e.target.value)}
                />
                <span className="input-group-addon">
                  {" "}
                  <span className="glyphicon glyphicon-calendar"></span>
                </span>
              </div>
            </div>
          </div>
          <div className="row d-flex ">
            <div className=" d-flex flex-column align-items-start">
              <label htmlFor="NomeAtendente">
                <strong>Nome do atendente</strong>{" "}
                <small className="form-text text-muted">Opcional</small>
              </label>
              <input
                type="text"
                name="Pesquisa[NomeAtendente]"
                id="NomeAtendente"
                className="form-control date"
                placeholder="Atendente"
                value={atendente}
                onChange={(e) => setAtendente(e.target.value)}
              />
            </div>
          </div>

          <div className="form-group choose-seguimento ">
            <label>
              <strong>Qual seu segmento de atuação?</strong>
            </label>
            <div className="form-check radio radio-primary d-flex d-flex">
              <input
                className="form-check-input"
                type="radio"
                name="Pesquisa[Seguimento]"
                id="seguimento1"
                value="Advogado"
                checked={segmento === "Advogado"}
                onChange={(e) => setSegmento(e.target.value)}
              />
              <label className="form-check-label" htmlFor="seguimento1">
                Advogado
              </label>
            </div>
            <div className="form-check radio radio-primary d-flex">
              <input
                className="form-check-input"
                type="radio"
                name="Pesquisa[Seguimento]"
                id="seguimento2"
                value="Despachante"
                checked={segmento === "Despachante"}
                onChange={(e) => setSegmento(e.target.value)}
              />
              <label className="form-check-label" htmlFor="seguimento2">
                Despachante
              </label>
            </div>
            <div className="form-check radio radio-primary d-flex">
              <input
                className="form-check-input"
                type="radio"
                name="Pesquisa[Seguimento]"
                id="seguimento3"
                value="Serviço público"
                checked={segmento === "Serviço público"}
                onChange={(e) => setSegmento(e.target.value)}
              />
              <label className="form-check-label" htmlFor="seguimento3">
                Serviço público
              </label>
            </div>
            <div className="form-check radio radio-primary d-flex">
              <input
                className="form-check-input"
                type="radio"
                name="Pesquisa[Seguimento]"
                id="seguimento4"
                value="Imobiliária / Corretor"
                checked={segmento === "Imobiliária / Corretor"}
                onChange={(e) => setSegmento(e.target.value)}
              />
              <label className="form-check-label" htmlFor="seguimento4">
                Imobiliária / Corretor
              </label>
            </div>
            <div className="form-check radio radio-primary d-flex">
              <input
                className="form-check-input"
                type="radio"
                name="Pesquisa[Seguimento]"
                id="seguimento5"
                value="Construtora / Incorporadora"
                checked={segmento === "Construtora / Incorporadora"}
                onChange={(e) => setSegmento(e.target.value)}
              />
              <label className="form-check-label" htmlFor="seguimento5">
                Construtora / Incorporadora
              </label>
            </div>
            <div className="form-check radio radio-primary d-flex">
              <input
                className="form-check-input"
                type="radio"
                name="Pesquisa[Seguimento]"
                id="seguimento6"
                value="Particular"
                checked={segmento === "Particular"}
                onChange={(e) => setSegmento(e.target.value)}
              />
              <label className="form-check-label" htmlFor="seguimento6">
                Particular
              </label>
            </div>
            <div className="form-check radio radio-primary d-flex">
              <input
                className="form-check-input"
                type="radio"
                name="Pesquisa[Seguimento]"
                id="seguimento7"
                value="Outro"
                checked={segmento === "Outro"}
                onChange={(e) => setSegmento(e.target.value)}
              />
              <label className="form-check-label" htmlFor="seguimento7">
                Outro
              </label>
            </div>

            <input
              type="text"
              name="Pesquisa[Seguimento]"
              className="form-control"
              placeholder="Qual é o seguimento ?"
              value={outroSeg}
              onChange={(e) => setOutroSeg(e.target.value)}
            />
          </div>
          <p></p>
          <div className="form-group">
            <label>
              <strong>
                Indique o seu grau de satisfação com os aspectos listados:
              </strong>
            </label>
            <div className="table-responsive">
              <table className="table table-check geral-check table-striped">
                <thead>
                  <tr>
                    <th className="tquestion"></th>
                    <th className="tcheck">Excelente</th>
                    <th className="tcheck">Bom</th>
                    <th className="tcheck">Regular</th>
                    <th className="tcheck">Ruim</th>
                    <th className="tcheck">Péssimo</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Educação do atendente</td>
                    <td className="check">
                      <div className="form-check radio radio-success">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="Pesquisa[EducacaoAtendente]"
                          id="EducacaoAtendente1"
                          value="Excelente"
                          checked={educacao === "Excelente"}
                          onChange={(e) => setEducacao(e.target.value)}
                        />
                      </div>
                    </td>
                    <td className="check center">
                      <div className="form-check radio radio-primary d-flex">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="Pesquisa[EducacaoAtendente]"
                          id="EducacaoAtendente2"
                          value="Bom"
                          checked={educacao === "Bom"}
                          onChange={(e) => setEducacao(e.target.value)}
                        />
                      </div>
                    </td>
                    <td className="check">
                      <div className="form-check radio radio-warning">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="Pesquisa[EducacaoAtendente]"
                          id="EducacaoAtendente3"
                          value="Regular"
                          checked={educacao === "Regular"}
                          onChange={(e) => setEducacao(e.target.value)}
                        />
                      </div>
                    </td>
                    <td className="check">
                      <div className="form-check radio radio-danger">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="Pesquisa[EducacaoAtendente]"
                          id="EducacaoAtendente4"
                          value="Ruim"
                          checked={educacao === "Ruim"}
                          onChange={(e) => setEducacao(e.target.value)}
                        />
                      </div>
                    </td>
                    <td className="check">
                      <div className="form-check radio radio-danger">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="Pesquisa[EducacaoAtendente]"
                          id="EducacaoAtendente5"
                          value="Péssimo"
                          checked={educacao === "Péssimo"}
                          onChange={(e) => setEducacao(e.target.value)}
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Clareza na explicação do atendente</td>
                    <td className="check">
                      <div className="form-check radio radio-success">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="Pesquisa[ClarezaExplicacaoAtendente]"
                          id="ClarezaExplicacaoAtendente1"
                          value="Excelente"
                          checked={clareza === "Excelente"}
                          onChange={(e) => setClareza(e.target.value)}
                        />
                      </div>
                    </td>
                    <td className="check">
                      <div className="form-check radio radio-primary d-flex">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="Pesquisa[ClarezaExplicacaoAtendente]"
                          id="ClarezaExplicacaoAtendente2"
                          value="Bom"
                          checked={clareza === "Bom"}
                          onChange={(e) => setClareza(e.target.value)}
                        />
                      </div>
                    </td>
                    <td className="check">
                      <div className="form-check radio radio-warning">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="Pesquisa[ClarezaExplicacaoAtendente]"
                          id="ClarezaExplicacaoAtendente3"
                          value="Regular"
                          checked={clareza === "Regular"}
                          onChange={(e) => setClareza(e.target.value)}
                        />
                      </div>
                    </td>
                    <td className="check">
                      <div className="form-check radio radio-danger">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="Pesquisa[ClarezaExplicacaoAtendente]"
                          id="ClarezaExplicacaoAtendente4"
                          value="Ruim"
                          checked={clareza === "Ruim"}
                          onChange={(e) => setClareza(e.target.value)}
                        />
                      </div>
                    </td>
                    <td className="check">
                      <div className="form-check radio radio-danger">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="Pesquisa[ClarezaExplicacaoAtendente]"
                          id="ClarezaExplicacaoAtendente5"
                          value="Péssimo"
                          checked={clareza === "Péssimo"}
                          onChange={(e) => setClareza(e.target.value)}
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Organização do Cartório</td>
                    <td className="check">
                      <div className="form-check radio radio-success">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="Pesquisa[OrganizacaoCartorio]"
                          id="OrganizacaoCartorio1"
                          value="Excelente"
                          checked={organizacao === "Excelente"}
                          onChange={(e) => setOrganizacao(e.target.value)}
                        />
                      </div>
                    </td>
                    <td className="check">
                      <div className="form-check radio radio-primary d-flex">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="Pesquisa[OrganizacaoCartorio]"
                          id="OrganizacaoCartorio2"
                          value="Bom"
                          checked={organizacao === "Bom"}
                          onChange={(e) => setOrganizacao(e.target.value)}
                        />
                      </div>
                    </td>
                    <td className="check">
                      <div className="form-check radio radio-warning">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="Pesquisa[OrganizacaoCartorio]"
                          id="OrganizacaoCartorio3"
                          value="Regular"
                          checked={organizacao === "Regular"}
                          onChange={(e) => setOrganizacao(e.target.value)}
                        />
                      </div>
                    </td>
                    <td className="check">
                      <div className="form-check radio radio-danger">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="Pesquisa[OrganizacaoCartorio]"
                          id="OrganizacaoCartorio4"
                          value="Ruim"
                          checked={organizacao === "Ruim"}
                          onChange={(e) => setOrganizacao(e.target.value)}
                        />
                      </div>
                    </td>
                    <td className="check">
                      <div className="form-check radio radio-danger">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="Pesquisa[OrganizacaoCartorio]"
                          id="OrganizacaoCartorio5"
                          value="Péssimo"
                          checked={organizacao === "Péssimo"}
                          onChange={(e) => setOrganizacao(e.target.value)}
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Rapidez no atendimento</td>
                    <td className="check">
                      <div className="form-check radio radio-success">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="Pesquisa[RapidezAtendimento]"
                          id="RapidezAtendimento1"
                          value="Excelente"
                          checked={rapidez === "Excelente"}
                          onChange={(e) => setRapidez(e.target.value)}
                        />
                      </div>
                    </td>
                    <td className="check">
                      <div className="form-check radio radio-primary d-flex">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="Pesquisa[RapidezAtendimento]"
                          id="RapidezAtendimento2"
                          value="Bom"
                          checked={rapidez === "Bom"}
                          onChange={(e) => setRapidez(e.target.value)}
                        />
                      </div>
                    </td>
                    <td className="check">
                      <div className="form-check radio radio-warning">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="Pesquisa[RapidezAtendimento]"
                          id="RapidezAtendimento3"
                          value="Regular"
                          checked={rapidez === "Regular"}
                          onChange={(e) => setRapidez(e.target.value)}
                        />
                      </div>
                    </td>
                    <td className="check">
                      <div className="form-check radio radio-danger">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="Pesquisa[RapidezAtendimento]"
                          id="RapidezAtendimento4"
                          value="Ruim"
                          checked={rapidez === "Ruim"}
                          onChange={(e) => setRapidez(e.target.value)}
                        />
                      </div>
                    </td>
                    <td className="check">
                      <div className="form-check radio radio-danger">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="Pesquisa[RapidezAtendimento]"
                          id="RapidezAtendimento5"
                          value="Péssimo"
                          checked={rapidez === "Péssimo"}
                          onChange={(e) => setRapidez(e.target.value)}
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Qual a sua opinião sobre os outros cartórios de Senador Canedo
                    </td>
                    <td className="check ">
                      <div className="form-check radio radio-success">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="Pesquisa[OutrosCartorio]"
                          id="OutrosCartorio1"
                          value="Excelente"
                          checked={outrosRI === "Excelente"}
                          onChange={(e) => setOutrosRI(e.target.value)}
                        />
                      </div>
                    </td>
                    <td className="check">
                      <div className="form-check radio radio-primary d-flex">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="Pesquisa[OutrosCartorio]"
                          id="OutrosCartorio2"
                          value="Bom"
                          checked={outrosRI === "Bom"}
                          onChange={(e) => setOutrosRI(e.target.value)}
                        />
                      </div>
                    </td>
                    <td className="check">
                      <div className="form-check radio radio-warning">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="Pesquisa[OutrosCartorio]"
                          id="OutrosCartorio3"
                          value="Regular"
                          checked={outrosRI === "Regular"}
                          onChange={(e) => setOutrosRI(e.target.value)}
                        />
                      </div>
                    </td>
                    <td className="check">
                      <div className="form-check radio radio-danger">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="Pesquisa[OutrosCartorio]"
                          id="OutrosCartorio4"
                          value="Ruim"
                          checked={outrosRI === "Ruim"}
                          onChange={(e) => setOutrosRI(e.target.value)}
                        />
                      </div>
                    </td>
                    <td className="check">
                      <div className="form-check radio radio-danger">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="Pesquisa[OutrosCartorio]"
                          id="OutrosCartorio5"
                          value="Péssimo"
                          checked={outrosRI === "Péssimo"}
                          onChange={(e) => setOutrosRI(e.target.value)}
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className="form-group geral-check">
            <label>
              <strong>
                Dê uma nota de 0 a 10 para a pessoa que lhe atendeu
              </strong>
            </label>
            <p></p>
            <div className="checks-inline check geral-check">
              <div className="form-check radio radio-danger">
                <input
                  className="form-check-input"
                  type="radio"
                  name="Pesquisa[NotaAtendente]"
                  id="NotaAtendente0"
                  value="0"
                  checked={notaAtendente === "0"}
                  onChange={(e) => setNotaAtendente(e.target.value)}
                />
                <label className="form-check-label" htmlFor="NotaAtendente0">
                  0
                </label>
              </div>
              <div className="radio radio-primary radio-inline">
                <input
                  type="radio"
                  name="Pesquisa[NotaAtendente]"
                  id="NotaAtendente1"
                  className="form-check-input"
                  value="1"
                  checked={notaAtendente === "1"}
                  onChange={(e) => setNotaAtendente(e.target.value)}
                />
                <label className="form-check-label" htmlFor="NotaAtendente1">
                  1
                </label>
              </div>
              <div className="radio radio-primary radio-inline">
                <input
                  type="radio"
                  name="Pesquisa[NotaAtendente]"
                  id="NotaAtendente2"
                  className="form-check-input"
                  value="2"
                  checked={notaAtendente === "2"}
                  onChange={(e) => setNotaAtendente(e.target.value)}
                />
                <label className="form-check-label" htmlFor="NotaAtendente2">
                  2
                </label>
              </div>
              <div className="radio radio-primary radio-inline">
                <input
                  type="radio"
                  name="Pesquisa[NotaAtendente]"
                  id="NotaAtendente3"
                  className="form-check-input"
                  value="3"
                  checked={notaAtendente === "3"}
                  onChange={(e) => setNotaAtendente(e.target.value)}
                />
                <label className="form-check-label" htmlFor="NotaAtendente3">
                  3
                </label>
              </div>
              <div className="radio radio-primary radio-inline">
                <input
                  type="radio"
                  name="Pesquisa[NotaAtendente]"
                  id="NotaAtendente4"
                  className="form-check-input"
                  value="4"
                  checked={notaAtendente === "4"}
                  onChange={(e) => setNotaAtendente(e.target.value)}
                />
                <label className="form-check-label" htmlFor="NotaAtendente4">
                  4
                </label>
              </div>
              <div className="radio radio-primary radio-inline">
                <input
                  type="radio"
                  name="Pesquisa[NotaAtendente]"
                  id="NotaAtendente5"
                  className="form-check-input"
                  value="5"
                  checked={notaAtendente === "5"}
                  onChange={(e) => setNotaAtendente(e.target.value)}
                />
                <label className="form-check-label" htmlFor="NotaAtendente5">
                  5
                </label>
              </div>
              <div className="radio radio-primary radio-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="Pesquisa[NotaAtendente]"
                  id="NotaAtendente6"
                  value="6"
                  checked={notaAtendente === "6"}
                  onChange={(e) => setNotaAtendente(e.target.value)}
                />
                <label className="form-check-label" htmlFor="NotaAtendente6">
                  6
                </label>
              </div>
              <div className="radio radio-primary radio-inline">
                <input
                  type="radio"
                  name="Pesquisa[NotaAtendente]"
                  id="NotaAtendente7"
                  className="form-check-input"
                  value="7"
                  checked={notaAtendente === "7"}
                  onChange={(e) => setNotaAtendente(e.target.value)}
                />
                <label className="form-check-label" htmlFor="NotaAtendente7">
                  7
                </label>
              </div>
              <div className="radio radio-primary radio-inline">
                <input
                  type="radio"
                  name="Pesquisa[NotaAtendente]"
                  id="NotaAtendente8"
                  className="form-check-input"
                  value="8"
                  checked={notaAtendente === "8"}
                  onChange={(e) => setNotaAtendente(e.target.value)}
                />
                <label className="form-check-label" htmlFor="NotaAtendente8">
                  8
                </label>
              </div>
              <div className="radio radio-primary radio-inline">
                <input
                  type="radio"
                  name="Pesquisa[NotaAtendente]"
                  id="NotaAtendente9"
                  className="form-check-input"
                  value="9"
                  checked={notaAtendente === "9"}
                  onChange={(e) => setNotaAtendente(e.target.value)}
                />
                <label className="form-check-label" htmlFor="NotaAtendente9">
                  9
                </label>
              </div>
              <div className="radio radio-primary radio-inline">
                <input
                  type="radio"
                  name="Pesquisa[NotaAtendente]"
                  id="NotaAtendente10"
                  className="form-check-input"
                  value="10"
                  checked={notaAtendente === "10"}
                  onChange={(e) => setNotaAtendente(e.target.value)}
                />
                <label className="form-check-label" htmlFor="NotaAtendente10">
                  10
                </label>
              </div>
            </div>
          </div>
          <p></p>
          <div className="form-group">
            <label>
              <strong>
                Dê uma nota de 0 a 10 para sua satisfação com o Cartório
              </strong>
            </label>
            <p></p>
            <div className="checks-inline">
              <div className="radio radio-primary radio-inline ">
                <input
                  type="radio"
                  name="Pesquisa[NotaCartorio]"
                  id="NotaCartorio0"
                  className="form-check-input"
                  value="0"
                  checked={notaCartorio === "0"}
                  onChange={(e) => setNotaCartorio(e.target.value)}
                />
                <label className="form-check-label" htmlFor="NotaCartorio0">
                  0
                </label>
              </div>

              <div className="radio radio-primary radio-inline">
                <input
                  type="radio"
                  name="Pesquisa[NotaCartorio]"
                  id="NotaCartorio1"
                  className="form-check-input"
                  value="1"
                  checked={notaCartorio === "1"}
                  onChange={(e) => setNotaCartorio(e.target.value)}
                />
                <label className="form-check-label" htmlFor="NotaCartorio1">
                  1
                </label>
              </div>
              <div className="radio radio-primary radio-inline">
                <input
                  type="radio"
                  name="Pesquisa[NotaCartorio]"
                  id="NotaCartorio2"
                  className="form-check-input"
                  value="2"
                  checked={notaCartorio === "2"}
                  onChange={(e) => setNotaCartorio(e.target.value)}
                />
                <label className="form-check-label" htmlFor="NotaCartorio2">
                  2
                </label>
              </div>
              <div className="radio radio-primary radio-inline">
                <input
                  type="radio"
                  name="Pesquisa[NotaCartorio]"
                  id="NotaCartorio3"
                  className="form-check-input"
                  value="3"
                  checked={notaCartorio === "3"}
                  onChange={(e) => setNotaCartorio(e.target.value)}
                />
                <label className="form-check-label" htmlFor="NotaCartorio3">
                  3
                </label>
              </div>
              <div className="radio radio-primary radio-inline">
                <input
                  type="radio"
                  name="Pesquisa[NotaCartorio]"
                  id="NotaCartorio4"
                  className="form-check-input"
                  value="4"
                  checked={notaCartorio === "4"}
                  onChange={(e) => setNotaCartorio(e.target.value)}
                />
                <label className="form-check-label" htmlFor="NotaCartorio4">
                  4
                </label>
              </div>
              <div className="radio radio-primary radio-inline">
                <input
                  type="radio"
                  name="Pesquisa[NotaCartorio]"
                  id="NotaCartorio5"
                  className="form-check-input"
                  value="5"
                  checked={notaCartorio === "5"}
                  onChange={(e) => setNotaCartorio(e.target.value)}
                />
                <label className="form-check-label" htmlFor="NotaCartorio5">
                  5
                </label>
              </div>
              <div className="radio radio-primary radio-inline">
                <input
                  type="radio"
                  name="Pesquisa[NotaCartorio]"
                  id="NotaCartorio6"
                  className="form-check-input"
                  value="6"
                  checked={notaCartorio === "6"}
                  onChange={(e) => setNotaCartorio(e.target.value)}
                />
                <label className="form-check-label" htmlFor="NotaCartorio6">
                  6
                </label>
              </div>
              <div className="radio radio-primary radio-inline">
                <input
                  type="radio"
                  name="Pesquisa[NotaCartorio]"
                  id="NotaCartorio7"
                  className="form-check-input"
                  value="7"
                  checked={notaCartorio === "7"}
                  onChange={(e) => setNotaCartorio(e.target.value)}
                />
                <label className="form-check-label" htmlFor="NotaCartorio7">
                  7
                </label>
              </div>
              <div className="radio radio-primary radio-inline">
                <input
                  type="radio"
                  name="Pesquisa[NotaCartorio]"
                  id="NotaCartorio8"
                  className="form-check-input"
                  value="8"
                  checked={notaCartorio === "8"}
                  onChange={(e) => setNotaCartorio(e.target.value)}
                />
                <label className="form-check-label" htmlFor="NotaCartorio8">
                  8
                </label>
              </div>
              <div className="radio radio-primary radio-inline">
                <input
                  type="radio"
                  name="Pesquisa[NotaCartorio]"
                  id="NotaCartorio9"
                  className="form-check-input"
                  value="9"
                  checked={notaCartorio === "9"}
                  onChange={(e) => setNotaCartorio(e.target.value)}
                />
                <label className="form-check-label" htmlFor="NotaCartorio9">
                  9
                </label>
              </div>
              <div className="radio radio-primary radio-inline">
                <input
                  type="radio"
                  name="Pesquisa[NotaCartorio]"
                  id="NotaCartorio10"
                  className="form-check-input"
                  value="10"
                  checked={notaCartorio === "10"}
                  onChange={(e) => setNotaCartorio(e.target.value)}
                />
                <label className="form-check-label" htmlFor="NotaCartorio10">
                  10
                </label>
              </div>
            </div>
          </div>
          <p></p>
          <div className="form-group">
            <label htmlFor="Elogio">
              <strong>Gostaria de fazer um elogio?</strong>
            </label>
            <textarea
              name="Pesquisa[Elogio]"
              id="Elogio"
              className="form-control autosize"
              placeholder="Elogio"
              value={elogio}
              onChange={(e) => setElogio(e.target.value)}
            ></textarea>
          </div>
          <p></p>
          <div className="form-group">
            <label htmlFor="CriticaSugestao">
              <strong>Faça uma crítica ou sugestão.</strong>
            </label>
            <textarea
              name="Pesquisa[CriticaSugestao]"
              id="CriticaSugestao"
              className="form-control autosize"
              placeholder="Crítica ou sugestão"
              value={sugestao}
              onChange={(e) => setSugestao(e.target.value)}
            ></textarea>
          </div>

          <div className="form-group mt-3 ">
            {!loading ? (
              <button type="submit" className="btn btn-dark">
                <i className="fa fa-rocket"></i> ENVIAR PESQUISA
              </button>
            ) : (
              <div>
                <img
                  style={{ width: "40px" }}
                  src={loadingGif}
                  alt="Loading gif"
                />
                Enviando...
              </div>
            )}
          </div>
          <div className="mt-3">
            {error && <MsgError id="erroID">{error}</MsgError>}
            {message && <MsgSucess id="messageID">{message}</MsgSucess>}
          </div>
        </form>
      </div>
    </div>
  );
};
