import "./App.css";
import { Navbar } from "./components/Navbar";
import { Route, Routes } from "react-router-dom";
import { Home } from "./views/Home";
import { Footer } from "./components/Footer";
import { QuemSomos } from "./views/QuemSomos";
import { Legislacao } from "./views/Legislacao";
import { ModeloReq } from "./views/ModeloReq";
import { PedidosCertidao } from "./views/PedidosCertidao";
import { RegFaq } from "./views/RegFaq";
import { ConsultaProtocolo } from "./views/ConsultaProtocolo";
import { ConsultaSelo } from "./views/ConsultaSelo";
import { PjFaq } from "./views/PjFaq";
import { PorqueRegistrar } from "./views/PorqueRegistrar";
import { ModeloReqPj } from "./views/ModeloReqPj";
import { OqueRegistrarPj } from "./views/OqueRegistrarPj";
import { OqueRegistrarRtd } from "./views/OqueRegistrarRtd";
import { TipoCertidoes } from "./views/TipoCertidoes";
import { OqueCertidao } from "./views/OqueCertidao";
// import { FaleConosco } from "./views/FaleConosco";
import { PesquisaSatisfacao } from "./views/PesquisaSatisfacao";
import { CustasEmolumentos } from "./views/CustasEmolumentos";
import CookiesAlert from "./components/CookiesAlert";
import { useState } from "react";
import NotFound from "./views/NotFound";
import { Links } from "./views/Links";
import LgpdForm from "./views/LgpdForm";
import { Ouvidoria } from "./views/Ouvidoria";
import { PrazosLegais } from "./views/PrazosLegais";
//import PDFViewer from "./views/PDFViewer";

function App() {
  const [message, setMessage] = useState();
  return (
    <div className="App">
      <Navbar />
      <CookiesAlert />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/quem-somos/" element={<QuemSomos />} />
        <Route path="/legislacao/" element={<Legislacao />} />
        <Route path="/modelos-requerimentos/" element={<ModeloReq />} />
        <Route path="/pedidos-certidao-online/" element={<PedidosCertidao />} />
        <Route path="/faq-registro/" element={<RegFaq />} />
        <Route path="/consulta-protocolo/" element={<ConsultaProtocolo />} />
        <Route path="/consulta-selo/" element={<ConsultaSelo />} />
        <Route path="/faq-pj/" element={<PjFaq />} />
        <Route path="/por-que-registrar/" element={<PorqueRegistrar />} />
        <Route path="/modelos-requerimentos-pj/" element={<ModeloReqPj />} />
        <Route path="/o-que-registrar-pj/" element={<OqueRegistrarPj />} />
        <Route path="/o-que-registrar-rtd/" element={<OqueRegistrarRtd />} />
        <Route path="/tipos-certidoes/" element={<TipoCertidoes />} />
        <Route path="/o-que-e-uma-certidao/" element={<OqueCertidao />} />
        <Route path="/links-uteis/" element={<Links />} />
        <Route
          path="/solicitacao-dados/"
          element={<LgpdForm message={message} setMessage={setMessage} />}
        />
        {/*<Route path="/politica-privacidade/" element={<PDFViewer />} />*/}
        <Route
          path="/ouvidoria/"
          element={<Ouvidoria message={message} setMessage={setMessage} />}
        />
        <Route
          path="/prazos-legais/"
          element={<PrazosLegais message={message} setMessage={setMessage} />}
        />
        <Route
          path="/pesquisa-satisfacao/"
          element={
            <PesquisaSatisfacao message={message} setMessage={setMessage} />
          }
        />
        <Route path="/custas-e-emolumentos/" element={<CustasEmolumentos />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
