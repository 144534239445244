import React from "react";
import { Link } from "react-router-dom";

export const Links = () => {
  return (
    <div className="margin-top">
      <div className="page-header-ri-background-modeloReq"></div>
      <div className="page-header-ri">
        <h3>Links Úteis</h3>
        <span className="subtitle"></span>
      </div>
      <div className="container mt-3 d-flex flex-column align-items-start quem-somos links-uteis">
        <Link className="mt-3"to="https://registradores.onr.org.br/" target="_blank">
          <i class="bi bi-hand-index-thumb"></i> Serviço de Atendimento Eletrônico
          Compartilhado - SAEC/ONR
        </Link>
        <Link className="mt-3"to="https://sinoreggoias.com.br/ouvidoria/" target="_blank">
          <i class="bi bi-hand-index-thumb"></i> SINOREG/GO - OUVIDORIA
        </Link>
        <Link className="mt-3"to="https://www.rtdbrasil.org.br/" target="_blank">
          <i class="bi bi-hand-index-thumb"></i> RTDPJ BRASIL
        </Link>
        <Link className="mt-3"to="https://corigoias.com.br" target="_blank">
          <i class="bi bi-hand-index-thumb"></i> Colégio Registral Imobiliário de Goiás
          - CORI/GO
        </Link>
        <Link className="mt-3"to="https://registrosdegoias.com.br" target="_blank">
          <i class="bi bi-hand-index-thumb"></i> Registros de Imóveis de Goiás
        </Link>
        <Link className="mt-3"
          to="https://drive.google.com/file/d/1mUkwykN0rHKJvybz5VRVP1gOavW8zLWY/view"
          target="_blank"
        >
          <i class="bi bi-hand-index-thumb"></i> Código Nacional de Normas da
          Corregedoria Nacional de Justiça do Conselho Nacional de Justiça —
          Foro Extrajudicial
        </Link>
        <Link className="mt-3"to="https://tjdocs.tjgo.jus.br/documentos/690586" target="_blank">
          <i class="bi bi-hand-index-thumb"></i> Código de Normas e Procedimentos do
          Foro Extrajudicial do Estado de Goiás
        </Link>
      </div>
    </div>
  );
};
