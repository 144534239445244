import React from "react";
import { Link } from "react-router-dom";
import logoIury from "../imgs/iury.png";
import logo from "../imgs/logo transparente.png";
export const Footer = () => {
  return (
    <div style={{ backgroundColor: "#415161" }}>
      <div className="container w-100 px-3">
        <div className="footer-ri row  ">
          <ul className="d-flex  col-sm-6 col-md-4 col-xl-3 flex-column align-items-start">
            <p>Acesso Rápido</p>
            <li>
              <Link to="/">Início</Link>
            </li>
            <li>
              <Link to="/quem-somos/">Quem somos</Link>
            </li>
            <li>
              <Link to="/modelos-requerimentos/">
                Modelos de Requerimentos - RI
              </Link>
            </li>
            <li>
              <Link to="/modelos-requerimentos-pj/">
                Modelos de Requerimentos - RTDPJ
              </Link>
            </li>
            <li>
              <Link to="/fale-conosco/">Fale conosco</Link>
            </li>
            <li>
              <Link to="/links-uteis/">Links Úteis</Link>
            </li>
          </ul>
          <ul className="d-flex col-sm-6 col-md-4 col-xl-3 flex-column align-items-start">
            <p>Serviços</p>
            <li>
              <Link to="/consulta-protocolo/">
                Consultar Protocolo/Certidão - RI
              </Link>
            </li>
            <li>
              <Link to="/consulta-selo/">Consultar Selo</Link>
            </li>
            <li>
              <Link to="/pesquisa-satisfacao/">Pesquisa de Satisfação</Link>
            </li>
            <li>
              <Link to="/custas-e-emolumentos/">Custas e Emolumentos</Link>
            </li>
            <li>
              <Link to="/o-que-e-uma-certidao/">O que é Certidão?</Link>
            </li>
          </ul>
          <ul className="d-flex col-sm-6 col-md-4 col-xl-3 flex-column align-items-start">
            <p>Localização</p>
            <li>Av. Central, Qd. 11, Lote 23A</li>
            <li>Salas 01 a 03, Conjunto Uirapuru</li>
            <li>Senador Canedo -GO</li>
            <li>CEP: 75.261-232</li>
          </ul>
          <ul className="d-flex  col-sm-6 col-md-4 col-xl-3 flex-column align-items-start">
            <img
              src={logo}
              style={{ filter: "invert(20%)" }}
              alt="Logo Cartório"
            />
          </ul>
        </div>
      </div>
      <footer className="d-flex flex-wrap justify-content-around align-items-center  border-top no-mobile">
        <Link to="https://iuryflores.com.br" target="_blank">
          Desenvolvido por Iury Flores
        </Link>
        <Link to="https://iuryflores.com.br" target="_blank">
          <img src={logoIury} alt="Logotipo 1RIGO" />
        </Link>{" "}
        <div className="logo">
          © 2023 | Todos os direitos reservados - Cartório Registro de Imóveis
          Senador Canedo
        </div>
      </footer>
    </div>
  );
};
