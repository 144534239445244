import React from "react";
/*
import file1 from "../storage/ri/01 - Requerimento_generico_novo.docx";
import file2 from "../storage/ri/02 - Registro_Parcial_do_Titulo_novo.doc";
import file3 from "../storage/ri/03 - Ajuizamento De Execucao.doc";
import file4 from "../storage/ri/04 - Cancelamento_de_Clausula_Resolutiva_novo.doc";
import file5 from "../storage/ri/05 - Cancelamento_de_Usufruto.doc";
import file6 from "../storage/ri/06 - Averbacao De Casamento.doc";
import file7 from "../storage/ri/07 - Construcao.doc";
import file8 from "../storage/ri/08 - Construcao_de_ate_70m.doc";
import file9 from "../storage/ri/09 - Declaracao_de_ciencia_de_teor_de_certidao.docx";
import file10 from "../storage/ri/10 - Declaracao_de_domicilio_conjugal.doc";
import file11 from "../storage/ri/11 - Declaracao_de_inexistencia_de_condominio.doc";
import file12 from "../storage/ri/12 - Demolicao_de_edificacao.doc";
import file13 from "../storage/ri/13 - Dispensa_Certidao_Civel.doc";
import file14 from "../storage/ri/14 - Obito.doc";
import file15 from "../storage/ri/15 - Averbacao_de_pacto_antenupcial_livro_2_matriculas_do_imovel.docx";
import file16 from "../storage/ri/16 - Separacao_e_Divorcio.docx";
import file17 from "../storage/ri/17 - requerimento-de-av-de-construcao-condominio.docx";
import file18 from "../storage/ri/18 - requerimento-de-incorporacao-de-condominio.docx";
import file19 from "../storage/ri/19 - requerimento-de-intituicao-de-condominio.docx";
*/
import { Link } from "react-router-dom";

export const ModeloReq = () => {
  const urlIury = "https://iuryflores.com.br/storage/riscgo/ri/";
  const docs = [
    "REQUERIMENTO GENÉRICO 2024.pdf",
    "02 - Registro_Parcial_do_Titulo_novo.pdf",
    "03 - Ajuizamento De Execucao.pdf",
    "04 - Cancelamento_de_Clausula_Resolutiva_novo.pdf",
    "05 - Cancelamento_de_Usufruto.pdf",
    "06 - Averbacao De Casamento.pdf",
    "07 - Construcao.pdf",
    "08 - Construcao_de_ate_70m.pdf",
    "09 - Declaracao_de_ciencia_de_teor_de_certidao.pdf",
    "10 - Declaracao_de_domicilio_conjugal.pdf",
    "11 - Declaracao_de_inexistencia_de_condominio.pdf",
    "12 - Demolicao_de_edificacao.pdf",
    "13 - Dispensa_Certidao_Civel.pdf",
    "14 - Obito.pdf",
    "15 - Averbacao_de_pacto_antenupcial_livro_2_matriculas_do_imovel.pdf",
    "16 - Separacao_e_Divorcio.pdf",
    "17 - requerimento-de-av-de-construcao-condominio.pdf",
    "18 - requerimento-de-incorporacao-de-condominio.pdf",
    "19 - requerimento-de-intituicao-de-condominio.pdf",
  ];

  const handleClick = (url) => {
    const fileName = url.split("/").pop();
    const aTag = document.createElement("a");
    aTag.href = url;
    aTag.setAttribute("download", fileName);
    aTag.setAttribute("target", "_blank");
    document.body.appendChild(aTag);
    aTag.click();
    aTag.remove();
  };

  return (
    <div className="margin-top">
      <div className="page-header-ri-background-modeloReq"></div>
      <div className="page-header-ri">
        <h3>Modelos de requerimentos</h3>
        <span className="subtitle">Registro de Imóveis</span>
      </div>
      <div className="container mt-3 d-flex flex-column align-items-start quem-somos">
        <p>
          Para auxiliá-lo a solicitar serviços no cartório, seguem abaixo alguns
          modelos de requerimentos para preenchimento e assinatura.
        </p>
        <p>
          Não se esqueça de que sempre deve haver o reconhecimento da firma do
          requerente perante o tabelião.
        </p>
        <div class="modelos-req">
          <ul>
            <li>
              <Link
                target="_blank"
                onClick={() => handleClick(urlIury + docs[0])}
              >
                <i className="bi bi-file-earmark-text-fill"></i>
                Requerimento Genérico
              </Link>
            </li>
          </ul>
          <blockquote>
            <p>
              Modelo para usar em qualquer tipo de requerimento, sendo mais útil
              quando houver necessidade de solicitação de mais de um tipo de
              averbação.
            </p>
          </blockquote>
        </div>
        <div class="modelos-req">
          <ul>
            <li>
              <Link onClick={() => handleClick(urlIury + docs[1])}>
                <i className="bi bi-file-earmark-text-fill"></i> Requerimento de
                Registro Parcial
              </Link>
            </li>
          </ul>
          <blockquote>
            <p>
              Muitas vezes o título levado ao cartório se refere a mais de um
              imóvel (ex: formal de partilha no qual um imóvel ficou com um
              herdeiro diferente). E o usuário quer o registro apenas em relação
              a um imóvel. Não há problema. Podemos proceder ao registro de
              apenas um ou alguns dos imóveis no título, em regra. Então, nesse
              caso, deve-se usar este requerimento.
            </p>
          </blockquote>
        </div>
        <div class="modelos-req">
          <ul>
            <li>
              <Link onClick={() => handleClick(urlIury + docs[2])}>
                <i className="bi bi-file-earmark-text-fill"></i> Ajuizamento de
                execução – Art. 828 CPC/2015
              </Link>
            </li>
          </ul>
          <blockquote>
            <p>
              Usado para noticiar uma ação de execução ajuizada por alguém
              contra o proprietário do imóvel, visando a dar publicidade a tal
              fato e precaver o eventual terceiro adquirente.
            </p>
          </blockquote>
        </div>
        <div class="modelos-req">
          <ul>
            <li>
              <Link onClick={() => handleClick(urlIury + docs[3])}>
                <i className="bi bi-file-earmark-text-fill"></i> Cancelamento de
                Clausula Resolutiva
              </Link>
            </li>
          </ul>
          <blockquote>
            <p>
              Usado para cancelar determinada condição contratada anteriormente.
              Geralmente usa-se notas promissórias quando o valor do negocio não
              é pago integralmente ao vendedor.
            </p>
          </blockquote>
        </div>
        <div class="modelos-req">
          <ul>
            <li>
              <Link onClick={() => handleClick(urlIury + docs[4])}>
                <i className="bi bi-file-earmark-text-fill"></i> Cancelamento de
                Usufruto
              </Link>
            </li>
          </ul>
          <blockquote>
            <p>
              Usado para noticiar que o usufruto sobre um imóvel foi extinto em
              virtude do falecimento do usufrutuário.
            </p>
          </blockquote>
        </div>
        <div class="modelos-req">
          <ul>
            <li>
              <Link onClick={() => handleClick(urlIury + docs[5])}>
                <i className="bi bi-file-earmark-text-fill"></i>Casamento –
                Averbação
              </Link>
            </li>
          </ul>
          <blockquote>
            <p>
              Usado para noticiar que determinada pessoa se casou, e o regime de
              bens adotado.
            </p>
          </blockquote>
        </div>
        <div class="modelos-req">
          <ul>
            <li>
              <Link onClick={() => handleClick(urlIury + docs[6])}>
                <i className="bi bi-file-earmark-text-fill"></i> Construção
              </Link>
            </li>
          </ul>
          <blockquote>
            <p>
              Usado para noticiar que em determinado terreno foi construída uma
              edificação. (Ex: casa, sobrado, etc.).
            </p>
          </blockquote>
        </div>
        <div class="modelos-req">
          <ul>
            <li>
              <Link onClick={() => handleClick(urlIury + docs[7])}>
                <i className="bi bi-file-earmark-text-fill"></i> Construção de
                ate 70m²
              </Link>
            </li>
          </ul>
          <blockquote>
            <p>
              Usado para noticiar que em determinado terreno foi construída uma
              edificação de até 70,00m², situação em que o proprietário está
              isento da apresentação de CND do INSS, em virtude não ter sido
              utilizada mão de obra assalariada e se destina a uso próprio do
              tipo econômico.
            </p>
          </blockquote>
        </div>
        <div class="modelos-req">
          <ul>
            <li>
              <Link onClick={() => handleClick(urlIury + docs[8])}>
                <i className="bi bi-file-earmark-text-fill"></i> Declaração de
                Ciência de Teor de Certidão Positiva
              </Link>
            </li>
          </ul>
          <blockquote>
            <p>
              Usada para que os adquirentes declarem que sabem da existência de
              certidão (fiscal ou de feitos ajuizados) positiva (quando for o
              caso). É necessária apenas quando não constar a informação da
              certidão positiva na escritura pública ou contrato levado a
              registro.
            </p>
          </blockquote>
        </div>
        <div class="modelos-req">
          <ul>
            <li>
              <Link onClick={() => handleClick(urlIury + docs[9])}>
                <i className="bi bi-file-earmark-text-fill"></i> Declaração de
                domicílio conjugal
              </Link>
            </li>
          </ul>
          <blockquote>
            <p>
              Usada para comprovar o domicilio conjugal de uma determinada
              pessoa, a fim de viabilizar o registro de determinada escritura de
              pacto antenupcial.
            </p>
          </blockquote>
        </div>
        <div class="modelos-req">
          <ul>
            <li>
              <Link onClick={() => handleClick(urlIury + docs[10])}>
                <i className="bi bi-file-earmark-text-fill"></i> Declaração de
                inexistência de condomínio
              </Link>
            </li>
          </ul>
          <blockquote>
            <p>
              Usada pelos adquirentes de unidade de empreendimento novo, que
              ainda não teve o condomínio instituído.
            </p>
          </blockquote>
        </div>
        <div class="modelos-req">
          <ul>
            <li>
              <Link onClick={() => handleClick(urlIury + docs[11])}>
                <i className="bi bi-file-earmark-text-fill"></i> Declaração de
                Edificação
              </Link>
            </li>
          </ul>
          <blockquote>
            <p>
              Usado para noticiar que uma determinada edificação (construção)
              foi demolida.
            </p>
          </blockquote>
        </div>
        <div class="modelos-req">
          <ul>
            <li>
              <Link onClick={() => handleClick(urlIury + docs[12])}>
                <i className="bi bi-file-earmark-text-fill"></i>
                Dispensa certidão cível
              </Link>
            </li>
          </ul>
          <blockquote>
            <p>
              Usada quando os adquirentes querem dispensar a apresentação de
              certidão de feitos cíveis ajuizados referente aos alienantes.
            </p>
          </blockquote>
        </div>
        <div class="modelos-req">
          <ul>
            <li>
              <Link onClick={() => handleClick(urlIury + docs[13])}>
                <i className="bi bi-file-earmark-text-fill"></i>
                Óbito
              </Link>
            </li>
          </ul>
          <blockquote>
            <p>
              Usado para averbar determinado óbito antes de se registrar um
              inventário ou partilha.
            </p>
          </blockquote>
        </div>
        <div class="modelos-req">
          <ul>
            <li>
              <Link onClick={() => handleClick(urlIury + docs[14])}>
                <i className="bi bi-file-earmark-text-fill"></i>
                Pacto Antenupcial – Averbação nas matrículas
              </Link>
            </li>
          </ul>
          <blockquote>
            <p>
              Usado quando o proprietário é casado em regime de bens diverso do
              legal, como condição de eficácia do regime perante terceiros.
            </p>
          </blockquote>
        </div>
        <div class="modelos-req">
          <ul>
            <li>
              <Link onClick={() => handleClick(urlIury + docs[15])}>
                <i className="bi bi-file-earmark-text-fill"></i>
                Separação e Divórcio
              </Link>
            </li>
          </ul>
          <blockquote>
            <p>
              Usado para alterar o estado civil das partes envolvidas na
              matrícula. Em seguida deve ser registrada a partilha. Caso não
              tenha havido a partilha, os ex-cônjuges podem negociar o bem, mas
              ambos devem participar do negócio, assinando a escritura ou
              contrato.
            </p>
          </blockquote>
        </div>
        <div class="modelos-req">
          <ul>
            <li>
              <Link onClick={() => handleClick(urlIury + docs[16])}>
                <i className="bi bi-file-earmark-text-fill"></i>
                Requerimento de Av. de Construção - Condomínio
              </Link>
            </li>
          </ul>
          <blockquote>
            <p>Usado para averbar construção junto condomínio</p>
          </blockquote>
        </div>
        <div class="modelos-req">
          <ul>
            <li>
              <Link onClick={() => handleClick(urlIury + docs[17])}>
                <i className="bi bi-file-earmark-text-fill"></i>
                Requerimento de Incorporação de Condomínio
              </Link>
            </li>
          </ul>
          <blockquote>
            <p>Usado para solicitar o registro da incorporação</p>
          </blockquote>
        </div>
        <div class="modelos-req">
          <ul>
            <li>
              <Link onClick={() => handleClick(urlIury + docs[18])}>
                <i className="bi bi-file-earmark-text-fill"></i>
                Requerimento de Intituição de Condomínio
              </Link>
            </li>
          </ul>
          <blockquote>
            <p>
              Usado para solicitar o registro condomínio, e convenção de
              condomínio
            </p>
          </blockquote>{" "}
        </div>
      </div>
    </div>
  );
};
