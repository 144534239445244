import React, { useState } from "react";
import { Link } from "react-router-dom";

export const Menu = () => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [showDropdown1, setShowDropdown1] = useState(false);
  const [showDropdown2, setShowDropdown2] = useState(false);
  const [showDropdown3, setShowDropdown3] = useState(false);

  const toggleDropown = (number) => {
    switch (number) {
      case 0:
        setShowDropdown(!showDropdown);
        break;
      case 1:
        setShowDropdown1(!showDropdown1);
        break;
      case 2:
        setShowDropdown2(!showDropdown2);
        break;
      case 3:
        setShowDropdown3(!showDropdown3);
        break;
      default:
        break;
    }
  };

  return (
    <div>
      <ul className="navbar-nav mr-auto container d-flex justify-content-around align-items-center">
        <li className="nav-item">
          <Link className="nav-link" to="/">
            INÍCIO
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/quem-somos/">
            QUEM SOMOS
          </Link>
        </li>
        {/*<li className="nav-item">
          <Link className="nav-link" to="/noticias/">
            NOTÍCIAS
          </Link>
        </li>*/}
        <li
          className="nav-item dropdown"
          onMouseEnter={() => toggleDropown(0)}
          onMouseLeave={() => toggleDropown(0)}
        >
          <Link
            className="nav-link dropdown-toggle"
            to="#"
            id="navbarDropdown"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            REGISTRO DE IMÓVEIS
          </Link>
          {showDropdown && (
            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
              <Link className="dropdown-item" to="/faq-registro/">
                FAQ
              </Link>
              <Link className="dropdown-item" to="/legislacao/">
                LEGISLAÇÃO
              </Link>
              <Link className="dropdown-item" to="/consulta-protocolo/">
                CONSULTAR PROTOCOLO/CERTIDÃO
              </Link>
              <Link className="dropdown-item" to="/modelos-requerimentos/">
                MODELOS DE REQUERIMENTOS
              </Link>
              <Link
                className="dropdown-item"
                to="https://registradores.onr.org.br/Acesso.aspx"
                target="_blank"
              >
                PEDIDO DE CERTIDÃO ONLINE
              </Link>
            </div>
          )}
        </li>
        <li
          className="nav-item dropdown"
          onMouseEnter={() => toggleDropown(1)}
          onMouseLeave={() => toggleDropown(1)}
        >
          <Link
            className="nav-link dropdown-toggle"
            to="#"
            id="navbarDropdown"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            TÍTULOS, DOCUMENTOS E PJ
          </Link>
          {showDropdown1 && (
            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
              <Link className="dropdown-item" to="/por-que-registrar/">
                POR QUE REGISTRA?
              </Link>
              <Link className="dropdown-item" to="/modelos-requerimentos-pj/">
                MODELOS DE REQUERIMENTOS
              </Link>
              <Link className="dropdown-item" to="/o-que-registrar-rtd/">
                REGISTRO DE TÍTULOS E DOCUMENTOS
              </Link>
              <Link className="dropdown-item" to="/o-que-registrar-pj/">
                REGISTRO DE PESSOA JURÍDICA
              </Link>
              <Link className="dropdown-item" to="/tipos-certidoes/">
                TIPOS DE CERTIDÕES
              </Link>
            </div>
          )}
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/consulta-selo/">
            CONSULTA DE SELO
          </Link>
        </li>
        <li
          className="nav-item dropdown"
          onMouseEnter={() => toggleDropown(2)}
          onMouseLeave={() => toggleDropown(2)}
        >
          <Link
            className="nav-link dropdown-toggle"
            to="#"
            id="navbarDropdown"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            INFORMAÇÕES
          </Link>
          {showDropdown2 && (
            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
              <Link className="dropdown-item" to="/pesquisa-satisfacao/">
                PESQUISA DE SATISFAÇÃO
              </Link>
              <Link className="dropdown-item" to="/prazos-legais/">
                PRAZOS LEGAIS
              </Link>
              <Link className="dropdown-item" to="/custas-e-emolumentos/">
                CUSTAS E EMOLUMENTOS
              </Link>
              <Link className="dropdown-item" to="/o-que-e-uma-certidao/">
                O QUE É CERTIDÃO?
              </Link>
              <Link className="dropdown-item" to="/links-uteis/">
                LINKS ÚTEIS
              </Link>
              <Link
                className="dropdown-item"
                to="https://iuryflores.com.br/riscgo/Programa%20de%20Integridade.pdf"
                target="_blank"
              >
                PROGRAMA DE INTEGRIDADE
              </Link>
            </div>
          )}
        </li>
        <li
          className="nav-item dropdown"
          onMouseEnter={() => toggleDropown(3)}
          onMouseLeave={() => toggleDropown(3)}
        >
          <Link
            className="nav-link dropdown-toggle"
            to="#"
            id="navbarDropdown"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i className="bi bi-shield-lock-fill"></i> LGPD
          </Link>
          {showDropdown3 && (
            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
              <Link
                className="dropdown-item"
                to="https://iuryflores.com.br/riscgo/ADM%20-%20014%20Pol%C3%ADtica%20de%20Privacidade-%20LGPD%20-%2020241009.pdf"
                target="_blank"
              >
                POLÍTICA DE PRIVACIDADE
              </Link>
              <Link className="dropdown-item" to="/solicitacao-dados/">
                SOLICITAÇÃO DE DADOS
              </Link>
            </div>
          )}
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/ouvidoria/">
            OUVIDORIA
          </Link>
        </li>
      </ul>
    </div>
  );
};
