import React from "react";

export const Legislacao = () => {
  return (
    <div className="margin-top">
      <div className="page-header-ri-background-legislacao"></div>
      <div className="page-header-ri">
        <h3>Legislação</h3>
        <span className=""></span>
      </div>
      <div className="container mt-3 d-flex flex-column align-items-start quem-somos">
        <h4>
          Principais Leis sobre Registro de Imóveis em geral e também as
          peculiares de Goiás
        </h4>
        <p>
          Nas atividades notariais e de registros públicos há a necessária
          análise de aplicação de diversos diplomas legais, pois a{" "}
          <strong>
            fiscalização quanto ao respeito dos negócios à legislação vigente
          </strong>
          é um dos objetivos dos cartórios extrajudiciais, que, assim, conseguem
          eficientemente prevenir litígios e garantir a segurança jurídica no
          mundo negocial (não é por acaso que{" "}
          <strong>
            o Sistema de Registro de Imóveis Brasileiro é um dos mais seguros do
            mundo
          </strong>
          ,{" "}
          <i>
            conforme destaca o desembargador mineiro MARCELO GUIMARÃES RODRIGUES
          </i>
          ).
        </p>
        <p>
          Abaixo serão listadas algumas normas que costumam incidir
          frequentemente no cotidiano de um Cartório de Registro de Imóveis em
          Senador Canedo (pois há normas estaduais e municipais que também devem
          ser observadas).
        </p>
        <p>
          <strong>São as seguintes as normas referidas nesta seção:</strong>{" "}
          Constituição da República, Lei n. 8.935/94 (Lei dos Notários e
          Registradores), Lei n. 6.015/73 (Lei dos Registros Públicos),
          Consolidação de Atos Normativos da Corregedoria do TJGO, Lei n.
          7.433/85 (requisito da Escritura Pública), Decreto n. 93.240/86
          (regulamenta a Lei n. 7.433/85), Código Civil, Lei n. 10.169/2000
          (emolumentos em geral), Lei Estadual n. 14.376/02 (emolumentos de
          Goiás), Lei 13.097/15 (arts. 54 e seguintes: averbação de ações,
          fortalecendo o princípio da concentração na matrícula), Lei n.
          6.766/79 (loteamentos urbanos), Decreto n. 58/37 (loteamentos rurais),
          Lei n. 4.591/64 (incorporações), Decreto-Lei n. 167/67 e demais leis
          de Cédulas, Lei n. 8.212/91 (CND previdenciária), Lei Municipal 779/01
          (ITBI) e Lei Estadual n. 11.651/91 (ITCD).
        </p>
        <p>
          <strong>Hierarquia Normativa no Brasil</strong>
        </p>
        <p>A Hierarquia normativa no nosso país pode ser assim representada:</p>
        <p>
          1 – Constituição &gt; 2 – Leis (Complementares e Ordinárias – cada uma
          tem uma competência diversa) &gt; 3 – Decretos e Atos Normativos em
          geral regulamentadores de leis.
        </p>
        <p>
          <strong>
            Leis Federais, Estaduais e Municipais quanto à Atividade Notarial e
            de Registros Públicos.
          </strong>
        </p>
        <p>
          As Leis podem ser ainda federais, estaduais ou municipais. Como dito
          acima,{" "}
          <strong>só a União pode legislar sobre registros públicos</strong>
          (art. 22,XXV, CR/88). Então apenas uma lei federal pode dispor sobre o
          que pode ser registrado/averbado, onde pode ser registrado/averbado, e
          quais os efeitos deste registro/averbação. Normas administrativas de
          Tribunais costumam esclarecer alguns pontos duvidosos.
        </p>
        <p>
          Mas a <strong>Lei Estadual</strong> tem grande importância para a
          fixação dos valores de <strong>emolumentos</strong> e também para a
          normatização da situação organizacional dos cartórios em seu
          território.
        </p>
        <p>
          A Constituição, no art. 236, §2º, dispõe que a lei federal deve trazer
          apenas normas gerais sobre emolumentos. Essas normas gerais estão na
          Lei n. 10.169/2000.
        </p>
        <p>
          Mas valores específicos a serem cobrados dos usuários só podem ser
          estabelecidos por Lei Estadual. Em GOIÁS a Lei que trata do assunto é
          a Lei Estadual n. 14.376/02.
        </p>
        <p>
          Outro assunto importantíssimo para cartórios tratado na Lei Estadual é
          o <strong>ITCD</strong>, imposto que incide em transmissões de bens
          por herança ou doação. Em Goiás a lei que trata disto é a Lei Estadual
          n. 11.651/91 (Código Tributário Estadual de Goiás – arts. 72 ao 85).
        </p>
        <p>
          <strong>Lei municipal</strong> geralmente é importante para cartórios
          no que diz respeito à incidência de seus impostos nos negócios
          registrados (<strong>ITBI</strong>) e nos serviços dos próprios
          cartório (ISS). A lei que trata disso em Senador Canedo é a Lei 779/01
          (Código Tributário Municipal).
        </p>
        <p>
          Seguem a identificação das normas e explicações resumidas, com links
          para seus conteúdos:
        </p>
        <p>
          <a
            href="http://www.planalto.gov.br/ccivil_03/constituicao/ConstituicaoCompilado.htm"
            title="Constituição da República"
          >
            <strong>Constituição da República de 1988 (CR/88)</strong>
          </a>
          <span className="text-left">
            – Essa é a chamada “Lei Maior”, onde todas as outras leis do país se
            apoiam. Todas as demais leis e atos normativos em geral devem
            respeitar os comandos da Constituição. Os artigos mais importantes
            para os cartórios são: art. 1º, II (cidadania: o cartório de
            Registro Civil de Pessoas Naturais – RCPN – garante o exercício da
            cidadania, pois é necessário o registro do nascimento para o acesso
            a direitos decorrentes da cidadania), art. 5º, LXXVI (Gratuidade do
            registro de nascimento e certidão de óbito no RCPN), art. 22, XXV (a
            competência para legislar sobre registros públicos é da União), e
            art. 236 (determina que o regime da prestação do serviço seja por
            meio de delegação a um particular, após aprovação em concurso
            público).
          </span>
        </p>
        <p>
          Abaixo, quando for apenas indicado o número da lei, significa que é
          lei federal.
        </p>
        <p>
          <a
            href="http://www.planalto.gov.br/ccivil_03/leis/l8935.htm"
            title="Lei dos Notários e Registradores"
          >
            <strong>Lei 8.935/94</strong>
          </a>{" "}
          – <strong>Lei dos Notários e Registradores</strong>. Essa lei dá os
          contornos do conceito de um cartório. É por ela que se sabe o que é um
          cartório, as suas espécies, como são organizados e quem são os
          responsáveis pela prestação do serviço.
        </p>
        <p>
          <a
            href="http://www.planalto.gov.br/ccivil_03/leis/L6015compilada.htm"
            title="Lei dos Registros Públicos"
          >
            <strong>Lei 6.015/73</strong>
          </a>{" "}
          – <strong>Lei dos Registros Públicos</strong>. Prevê os procedimentos
          a serem observados nos cartórios de Registros (RCPN, RTD, RCPJ e RI).
        </p>
        <p>
          A partir do art. 167 trata do Registro de Imóveis. É muito antiga e em
          vários trechos já não está mais vigente. Costuma ser regulamentada de
          forma mais atualizada por Códigos de Normas dos Estados, que
          especificam alguns procedimentos a serem observados. Em Goiás, esse
          detalhamento e atualização é feito por meio da Consolidação de Atos
          Normativos da Corregedoria.
        </p>
        <p>
          <a
            href="http://www.tjgo.jus.br/docs/corregedoria/atosnormativos/DOC_consolidacao.pdf"
            title="Consolidação dos Atos Normativos da CGJ de Goiás"
          >
            <strong>
              Consolidação de Atos Normativos da Corregedoria de Goiás
              (CAN-CGJGO)
            </strong>
          </a>{" "}
          – É onde se encontram as normas da Corregedoria do Eg. Tribunal de
          Justiça de Goiás que detalham os procedimentos a serem observados por
          todos os cartórios. Os artigos 729 a 843 são os que tratam do Registro
          de Imóveis.
        </p>
        <p>
          <strong>
            <a
              href="http://www.planalto.gov.br/ccivil_03/leis/l7433.htm"
              title="Lei dos Requisitos da Escritura Pública"
            >
              Lei n. 7.433/85
            </a>{" "}
            – Requisitos da Escritura Pública
          </strong>
          : Prevê os requisitos da escritura pública, ou seja, o que deve
          constar na escritura pública. Deve ser aplicada em conjunto com o
          decreto que detalhou suas exigências (Decreto 93.240/86) e com o art.
          215 do Código Civil. Essas normas é que trazem, por exemplo, a
          exigência de apresentação de diversas certidões ao tabelião para a
          feitura de escritura pública.
        </p>
        <p>
          -{" "}
          <a
            href="http://www.planalto.gov.br/ccivil_03/decreto/Antigos/D93240.htm"
            title="Decreto regulamentador da Lei 7.433/85"
          >
            <strong>Decreto n. 93.240/86</strong>
          </a>
          : Detalhou as exigências da Lei n. 7.433/85.
        </p>
        <p>
          -{" "}
          <a
            href="http://www.planalto.gov.br/ccivil_03/leis/2002/l10406.htmUA&amp;bvm=bv.77880786,d.cWc"
            title="Código Civil"
          >
            <strong>Art. 215 do Código Civil de 2002</strong>
          </a>
          : Traz também requisitos da escritura pública.
        </p>
        <p>
          <a
            href="http://www.planalto.gov.br/ccivil_03/leis/l10169.htm"
            title="Lei Geral sobre Emolumentos de Cartórios"
          >
            <strong>Lei n. 10.169/2000 – Normas Gerais de Emolumentos</strong>
          </a>
          : É a lei federal com normas gerais sobre emolumentos. Cada Estado
          deve fixar os valores específicos dos emolumentos de seus cartórios,
          levando-se em conta as diretrizes gerais desta lei (ex: essa lei diz
          que é proibido fixar emolumentos com base em percentual sobre o
          negócio ou bem registrado).
        </p>
        <p>
          <strong>
            <a
              href="http://www.gabcivil.go.gov.br/leis_ordinarias/2002/lei_14376.htm"
              title="Lei de Emolumentos de Goiás"
            >
              Lei Estadual n. 14.376/02
            </a>{" "}
            – Valores dos Emolumentos em Goiás
          </strong>
          : Seguindo as diretrizes gerais da Lei federal n. 10.169/2000, Goiás
          fixou os emolumentos dos seus cartórios por meio desta lei estadual. A
          fixação de emolumentos por cada Estado é melhor para que se observe as
          grandes disparidades regionais existentes no nosso país de tamanho
          continental. Esses valores, conforme a Lei n. 10.169/2000, devem
          corresponder “ao efetivo custo e à adequada e suficiente remuneração
          dos serviços prestados”. A tabela de emolumentos de Goiás é uma das
          mais baixas do Brasil.
        </p>
        <p>
          <strong>
            <a
              href="http://www.planalto.gov.br/ccivil_03/leis/l6766.htm"
              title="Lei do Parcelamento do solo urbano"
            >
              Lei n. 6.766/79
            </a>{" "}
            – Lei do Parcelamento do solo Urbano
          </strong>
          : Esta lei regula as duas formas de parcelamento urbano existentes:
        </p>
        <p>
          <strong>1 – Loteamento</strong> (quando há criação de ruas ou
          alteração em ruas já existentes) e
        </p>
        <p>
          <strong>2 – Desmembramento</strong> (divisão de uma área em vários
          lotes sem a alteração ou criação de ruas existentes).
        </p>
        <p>
          O <strong>art. 18</strong> traz uma lista de documentos que devem ser
          apresentados pelo interessado ao Registro de Imóveis para que se
          registre o parcelamento.
        </p>
        <p>
          <strong>OBS</strong>: Mera divisão de um imóvel em alguns poucos lotes
          é considerado simples desdobro; não parcelamento. O número máximo para
          ser assim considerado varia de Estado para Estado, sendo, geralmente,
          dez (SP e MS, por exemplo, adotam este critério).
        </p>
        <p>
          <strong>
            <a
              href="http://www.planalto.gov.br/ccivil_03/decreto-lei/1937-1946/Del058.htm"
              title="Lei do Parcelamento do solo rural"
            >
              Decreto 58/37
            </a>{" "}
            – Lei do Parcelamento do solo Rural
          </strong>
          : Esta lei (na época o decreto tinha força de lei) regula os
          loteamentos feitos em imóvel rural. É necessário que haja aprovação do
          INCRA.
        </p>
        <p>
          <strong>
            <a
              href="http://www.planalto.gov.br/ccivil_03/leis/l4591.htm"
              title="Lei das Incorporações Imobiliárias"
            >
              Lei n. 4.591/64
            </a>{" "}
            – Lei de Incorporação e Condomínio
          </strong>
          : Regula o procedimento a ser seguido por quem quer fazer um
          incorporação e/ou instituir um condomínio. Se o imóvel está em regime
          de condomínio de edifícios (ex: apartamento, sala comercial) ou casas,
          essa lei exige que para a sua transmissão se comprove a quitação
          manutenção do condomínio (art. 4º.). Veja:
        </p>
        <p>
          Art. 4º A alienação de cada unidade, a transferência de direitos
          pertinentes à sua aquisição e a constituição de direitos reais sôbre
          ela independerão do consentimento dos condôminos,.
        </p>
        <p>
          Parágrafo único – A alienação ou transferência de direitos de que
          trata este artigo dependerá de prova de quitação das obrigações do
          alienante para com o respectivo condomínio.
        </p>
        <p>
          Conforme a Lei 7.433/85,{" "}
          <strong>
            para provar essa quitação basta a declaração feita por quem está
            transmitindo o imóvel
          </strong>
          , ou seu procurador, declaração esta que deve constar no contrato ou
          escritura (art. 2º, §2).
        </p>
        <p>
          O <strong>art. 32</strong>traz uma lista de documentos que devem ser
          apresentados pelo interessado ao Registro de Imóveis para que se
          registre a incorporação. Quanto a condomínios a Lei n. 4.591/64 foi
          derrogada pelos arts. 1331 a 1358 do Código Civil/2002 no que for
          incompatível.
        </p>
        <p>
          <strong>O que é uma Incorporação?</strong>
        </p>
        <p>
          Quando alguém tem a intenção de fazer a construção de unidades em
          condomínio (apartamentos, salas, garagens, ou casas) para futura
          venda, mas já quer vender essas unidades (que ainda nem existem) para
          financiar a obra, estaremos diante de uma “incorporação”.
          “Incorpora-se” a construção (edifício ou casas) ao terreno.
        </p>
        <p>
          Para que esse empreendedor possa vender desde logo essas unidades que
          ainda não existem fisicamente, a lei exige que ele registre a
          incorporação no cartório de Registro de Imóveis. Inclusive{" "}
          <strong>
            a venda dessas unidades futuras sem o prévio registro da
            incorporação é crime
          </strong>
          .
        </p>
        <p>
          A finalidade é proteger os compradores dessas unidades que ainda não
          existem. Qualquer pessoa pode saber os riscos da compra dessas
          unidades mediante consulta ao cartório de Registro de Imóveis.
        </p>
        <p>
          <strong>
            <a
              href="http://www.planalto.gov.br/ccivil_03/leis/l8212cons.htm"
              title="Lei de Custeio da Seguridade Social"
            >
              Lei n. 8.212/91
            </a>{" "}
            (Custeio do INSS):
          </strong>
          É a lei que prevê a obrigatoriedade de apresentação de CND de
          contribuições previdenciárias na compra e venda de imóveis, o que em
          regra é sempre exigível de empresas. Veja-se:
        </p>
        <p>
          <strong>Art. 47. É exigida Certidão Negativa de Débito-CND</strong>,
          fornecida pelo órgão competente, nos seguintes casos:{" "}
          <a href="http://www.planalto.gov.br/ccivil_03/leis/L9032.htm#art2">
            (Redação dada pela Lei nº 9.032, de 28.4.95).
          </a>
        </p>
        <p>
          <strong>I – da empresa:</strong>
        </p>
        <ol type="a">
          <li>
            na contratação com o Poder Público e no recebimento de benefícios ou
            incentivo fiscal ou creditício concedido por ele;
          </li>
          <li>
            <strong>
              na alienação ou oneração, a qualquer título, de bem imóvel ou
              direito a ele relativo;
            </strong>
          </li>
          <li>
            na alienação ou oneração, a qualquer título, de bem móvel de valor
            superior a Cr$ 2.500.000,00 (dois milhões e quinhentos mil
            cruzeiros) incorporado ao ativo permanente da empresa;
          </li>
          <li>
            no registro ou arquivamento, no órgão próprio, de ato relativo a
            baixa ou redução de capital de firma individual, redução de capital
            social, cisão total ou parcial, transformação ou extinção de
            entidade ou sociedade comercial ou civil e transferência de controle
            de cotas de sociedades de responsabilidade limitada;{" "}
            <a href="http://www.planalto.gov.br/ccivil_03/leis/L9528.htm#art1">
              (Redação dada pela Lei nº 9.528, de 10.12.97).
            </a>
          </li>
        </ol>
        <p>
          <strong>
            II – do proprietário, pessoa física ou jurídica, de obra de
            construção civil, quando de sua averbação no registro de imóveis,
            salvo no caso do inciso VIII do art. 30.
          </strong>
        </p>
        <p>
          <strong>Lei Municipal n.</strong>
          <strong> 779/01</strong>
          <strong> – Código Tributário Municipal de Senador Canedo </strong>
          (normas gerais, que se aplicam ao ITBI. É importante saber que
          qualquer não incidência, imunidade ou isenção de ITBI pretendida pelo
          interessado deve ser reconhecida em documento da Prefeitura.
        </p>
        <p>
          <strong>
            <a
              href="http://www.gabinetecivil.goias.gov.br/leis_ordinarias/1991/lei_11651.htm"
              title="Código Tributário de Goiás"
            >
              Lei Estadual n. 11.651/91
            </a>{" "}
            – Código Tributário Estadual de Goiás
          </strong>{" "}
          : Trata do ITCD (arts. 72 ao 85). O reconhecimento de não incidência,
          isenção ou imunidade também depende de manifestação do Estado.
        </p>
        <p>
          <strong>
            <a
              href="http://www.planalto.gov.br/ccivil_03/decreto-lei/del0167.htm"
              title="Lei das Cédulas de Crédito Rural"
            >
              Decreto-Lei 167/67
            </a>
            :
          </strong>{" "}
          Cédula de Crédito Rural. É importantíssima para o desenvolvimento da
          agricultura. Produtores rurais conseguem financiamentos em bancos para
          pagar as suas atividades e, em troca, assinam uma cédula. A cédula
          nada mais é que uma promessa de pagamento que alguém faz. As suas
          garantias podem ser penhor (geralmente os produtores dão em penhor a
          própria plantação ou animais) ou hipoteca (imóvel). As cédulas são
          registradas no Registro de Imóveis no Livro 3. Quando a garantia é a
          hipoteca, essa é registrada no Livro 2. Nesse caso, gera dois
          registros.
        </p>
        <p>
          Uns dos artigos mais importantes desse DL 167/67 são os seguintes:
        </p>
        <p>
          <strong>
            <em>
              Art 59. A venda dos bens apenhados ou hipotecados pela cédula de
              crédito rural depende de prévia anuência do credor, por escrito.
            </em>
          </strong>
        </p>
        <p>
          <em>
            Art 69.{" "}
            <strong>
              Os bens objeto de penhor ou de hipoteca constituídos pela cédula
              de crédito rural não serão penhorados, arrestados ou seqüestrados
              por outras dívidas
            </strong>{" "}
            do emitente ou do terceiro empenhador ou hipotecante, cumprindo ao
            emitente ou ao terceiro empenhador ou hipotecante denunciar a
            existência da cédula às autoridades incumbidas da diligência ou a
            quem a determinou, sob pena de responderem pelos prejuízos
            resultantes de sua omissão.
          </em>
        </p>
        <p>
          Isto é,{" "}
          <strong>
            se a hipoteca for cedular, a venda do imóvel depende de anuência do
            credor e nem penhorado pode ser
          </strong>
          .
        </p>
        <p>
          Atenção: a hipoteca normal não impede a venda do imóvel (basta ser
          declarado pelo comprador que sabe da sua existência).
        </p>
        <p>
          Depois da cédula rural, foram sendo criadas as cédulas de crédito de
          outras atividades econômicas, e as suas leis foram determinando que se
          aplicasse o Dec-Lei 167/67. O que há de acréscimo nas próximas cédulas
          é a possibilidade de garantia pela alienação fiduciária.
        </p>
        <p className="d-flex flex-column">
          <span>
            <a
              href="http://www.planalto.gov.br/ccivil_03/decreto-lei/1965-1988/Del0413.htm"
              title="Lei das Cédulas de Crédito Industrial"
            >
              <strong>Dec-Lei 413/69</strong>
            </a>
            : Cédula de Crédito Industrial.
          </span>
          <span>
            <a
              href="http://www.planalto.gov.br/ccivil_03/leis/L6313.htm"
              title="Lei das Cédilas de Crédito à Exportação"
            >
              <strong>Lei 6313/75</strong>
            </a>
            : Cédula de Crédito à Exportação.
          </span>
          <span>
            <a
              href="http://www.planalto.gov.br/ccivil_03/leis/1980-1988/L6840.htm"
              title="Lei das Cédulas de Crédito Comercial"
            >
              <strong>Lei 6840/80</strong>
            </a>
            : Cédula de Crédito Comercial
          </span>
          <span>
            <a
              href="http://www.planalto.gov.br/ccivil_03/leis/L8929.htm"
              title="Lei das Cédulas de Produto Rural"
            >
              <strong>Lei 8929/94</strong>
            </a>
            : Cédula de Produto Rural.<strong></strong>
          </span>
          <span>
            <a
              href="http://www.planalto.gov.br/ccivil_03/_ato2004-2006/2004/lei/l10.931.htm"
              title="Lei das Cédulas de Crédito Imobiliário"
            >
              <strong>Lei 10931/04</strong>
            </a>
            : Cédula de Crédito Imobiliário (CCI) e Cédula de Crédito Bancário
            (CCB).
          </span>
        </p>
        <p>
          A <strong>Cédula de Crédito Imobiliário</strong> é geralmente emitida
          pelo banco juntamente com o contrato de financiamento (compra e venda
          com mútuo). <strong>A CCI não é registrada no Livro 3</strong>. Apenas
          se <strong>averba</strong> no Livro 2 (matrícula). Para o cartório de
          Registro de Imóveis as seguintes normas são as mais importantes:
        </p>
        <p>
          <strong>
            <em>
              Art. 25. É vedada a averbação da emissão de CCI com garantia real
              quando houver prenotação ou registro de qualquer outro ônus real
              sobre os direitos imobiliários respectivos, inclusive penhora ou
              averbação de qualquer mandado ou ação judicial.
            </em>
          </strong>
        </p>
        <p>
          <em>
            Art. 52. Uma vez protocolizados todos os documentos necessários à
            averbação ou ao registro dos atos e dos títulos a que se referem
            esta Lei e a
          </em>
          <a href="http://www.planalto.gov.br/ccivil_03/LEIS/L9514.htm">
            <em>
              Lei n<sup>o</sup> 9.514, de 1997
            </em>
          </a>
          <em>
            , o oficial de Registro de Imóveis procederá ao registro ou à
            averbação, dentro do <strong>prazo de quinze dias.</strong>
          </em>
        </p>
        <p>
          A <strong>CCB</strong> é importante por que vem sendo muito usada
          pelos bancos para financiamentos em geral. Costumam usar como garantia
          principalmente a alienação fiduciária.{" "}
          <strong>A CCB não é registrada no Livro 3</strong>.{" "}
          <strong>Registra-se apenas a garantia</strong> (alienação fiduciária
          ou hipoteca) no Livro 2 (<strong>matrícula</strong>).
        </p>
        <p>
          <strong>
            <a
              href="http://www.planalto.gov.br/ccivil_03/_ato2011-2014/2014/Mpv/mpv656.htm"
              title="Lei da Concentração na Matrícula"
            >
              Lei 13.097/15, arts. 54 e seguintes (conversão da Medida
              Provisória n. 656/2014)
            </a>
            :
          </strong>{" "}
          Essa Lei trouxe uma mudança importantíssima no Direito Civil: agora
          fraudes a credores ou à execução, e evicção, só poderão ser alegadas
          quando as ações tiverem sido averbadas nas matrículas.
        </p>
        <p>
          <strong>
            A ideia é trazer tudo para a matrícula, fortalecendo o Princípio da
            Concentração
          </strong>
          . O que não estiver na matrícula não poderá ser oposto ao adquirente.
        </p>
        <p>
          Exemplo: alguém que tenha uma ação contra o doador não poderá “tirar”
          o imóvel do donatário, se essa ação não tiver sido previamente
          averbada na matrícula.
        </p>
        <p>
          O objetivo é facilitar a vida dos adquirentes de imóveis, mas sem
          perder a segurança, que será aumentada, pois tudo que puder de alguma
          forma atingir o imóvel terá que estar na matrícula.
        </p>{" "}
      </div>
    </div>
  );
};
