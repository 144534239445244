import React from "react";
import { Link } from "react-router-dom";
/*import doc1 from "./storage/rtd/01 - Requerimento de registro para conservacao.pdf";
import doc2 from "./storage/rtd/02 - Requerimentos de registro livro B.pdf";
import doc3 from "./storage/rtd/03 - Requerimentos de averbacao livro B.pdf";
import doc4 from "./storage/rtd/04 - Requerimento de registro livro A.pdf";
import doc5 from "./storage/rtd/05 - Requerimentos de averbacao livro A.pdf";
import doc6 from "./storage/rtd/06 - Requerimento valor declarado.pdf";
*/


const handleClick = (url) => {
  const fileName = url.split('/').pop()
  const aTag = document.createElement("a")
  aTag.href = url
  aTag.setAttribute("download", fileName)
  aTag.setAttribute('target', "_blank")
  document.body.appendChild(aTag)
  aTag.click()
  aTag.remove()
}



export const ModeloReqPj = () => {
  return (
    <div className="margin-top">
      <div className="page-header-ri-background-modeloReq"></div>
      <div className="page-header-ri">
        <h3>Modelos de requerimentos</h3>
        <span className="subtitle">
          Título, Documentos e Registro de Pessoa Jurídica
        </span>
      </div>
      <div className="container mt-3 d-flex flex-column align-items-start quem-somos">
        <p>
          Para auxiliá-lo a solicitar serviços no cartório, seguem abaixo alguns
          modelos de requerimentos para preenchimento e assinatura.
        </p>
        <p>
          Não se esqueça de que sempre deve haver o reconhecimento da firma do
          requerente perante o tabelião.
          <br />
          <br />
        </p>
        <p>
          1.{" "}
          <Link class="fr-file" onClick={() => handleClick("https://iuryflores.com.br/storage/riscgo/rtd/01%20-%20Requerimento%20de%20registro%20para%20conservacao.pdf")} target="_blank">
            Requerimento de registro para conservação de documento (s)
          </Link>
          <br />
          <br />
        </p>
        <p>
          2.{" "}
          <Link class="fr-file" onClick={() => handleClick("https://iuryflores.com.br/storage/riscgo/rtd/02 - Requerimentos de registro livro B.pdf")} target="_blank">
            Requerimento de registro no livro B (Títulos e Documentos)
          </Link>
          <br />
          <br />
        </p>
        <p>
          3.{" "}
          <Link class="fr-file" onClick={() => handleClick("https://iuryflores.com.br/storage/riscgo/rtd/03 - Requerimentos de averbacao livro B.pdf")} target="_blank">
            Requerimento de averbação no livro B (Títulos e Documentos)
          </Link>
          <br />
          <br />
        </p>
        <p>
          4.{" "}
          <Link class="fr-file" onClick={() => handleClick("https://iuryflores.com.br/storage/riscgo/rtd/04 - Requerimento de registro livro A.pdf")} target="_blank">
            Requerimento de registro livro A (Pessoas Jurídicas)
          </Link>
          <br />
          <br />
        </p>
        <p>
          5.{" "}
          <Link class="fr-file" onClick={() => handleClick("https://iuryflores.com.br/storage/riscgo/rtd/05 - Requerimentos de averbacao livro A.pdf")} target="_blank">
            Requerimento de averbação no livro A (Pessoas Jurídicas)
          </Link>
          <br />
          <br />
        </p>
        <p>
          6.{" "}
          <Link class="fr-file" onClick={() => handleClick("https://iuryflores.com.br/storage/riscgo/rtd/06 - Requerimento valor declarado.pdf ")} target="_blank">
            Requerimento com valor declarado
          </Link>
        </p>{" "}
      </div>
    </div>
  );
};
