import React, { useState } from "react";
import { Modal } from "react-bootstrap";

const NewsComp = ({ n1, title, author, news, credits }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  const handleShow = () => setShow(true);

  return (
    <>
      <div className="col-12 col-md-6 col-lg-3  " onClick={handleShow}>
        <div className="d-flex" style={{ width: "100%" }}>
          <img style={{ height: "150px" }} src={n1} alt="Notícia 1" />
          <div className="new-author">{author}</div>
          <div className="new-title">{title}</div>
        </div>
      </div>
      {/* Modal */}
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <h4 style={{ fontSize: "15px" }}>{title}</h4>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex flex-column align-items-center">
            <small>
              <span>{credits}</span>
            </small>
            <img className="w-75" src={n1} alt={title} />
          </div>
          <div
            className="d-flex flex-column new-content mt-3"
            dangerouslySetInnerHTML={{ __html: news }}
          ></div>
        </Modal.Body>
        <Modal.Footer>
          <small className="fs-6">{author}</small>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default NewsComp;
