import React from "react";

export const PjFaq = () => {
  const handleClick1 = () => {};
  const handleClick2 = () => {};
  return (
    <div className="margin-top">
      <div className="page-header-ri-background-question"></div>
      <div className="page-header-ri">
        <h3> Dúvidas Frequentes</h3>
        <span className="subtitle">
          Títulos, Documentos e Registro de Pessoa Jurídica
        </span>
      </div>
      <div className="quem-somos container">
        <div class="panel-respostas" id="panel-17">
          <button onClick={handleClick1}>
            <p class="panel-title">O que é RTD</p>
          </button>

          <div id="resposta-17" class="panel-collapse">
            <div class="panel-body">
              <p>Registro de título e documentos</p>
            </div>
          </div>
        </div>
        <div class="panel-respostas" id="panel-18">
          <button onClick={handleClick2}>
            <p class="panel-title">Teste RTD</p>
          </button>

          <div id="resposta-18" class="panel-collapse">
            <div class="panel-body">
              <p>teste RTD conteúo</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
