import React from "react";

export const PedidosCertidao = () => {
  return (
    <div className="margin-top">
      <div className="page-header-ri-background-formulario"></div>
      <div className="page-header-ri">
        <h3>Pedidos de Certidão Online</h3>
        <span className=""></span>
      </div>
      <div className="quem-somos">
        <form
          className=" container fs-5"
          data-request="onSend"
          data-request-flash="true"
          data-request-update="'uiCertidao::result' : '#content-form'"
          id="form-contato"
        >
          <div className=" ">
            <div className="row w-100">
              <div className="form-group col-md-6 d-flex flex-column align-items-start">
                <label for="Nome">Nome</label>
                <input
                  type="text"
                  name="nome"
                  id="Nome"
                  className="form-control"
                  placeholder="Seu nome"
                />
              </div>
              <div className="form-group col-md-6 d-flex flex-column align-items-start">
                <label for="phone">Telefone</label>
                <input
                  type="text"
                  name="telefone"
                  id="phone"
                  className="form-control fone"
                  placeholder="Telefone"
                  maxlength="15"
                />
              </div>
            </div>
          </div>

          <div className="form-group d-flex flex-column align-items-start">
            <label for="email">E-mail</label>
            <input
              type="email"
              name="email"
              id="email"
              className="form-control"
              placeholder="Seu email"
            />
          </div>

          <div className="form-group d-flex flex-column align-items-start">
            <label for="matriculas">Matriculas</label>
            <textarea
              name="matriculas"
              id="matriculas"
              className="form-control"
              rows="2"
              placeholder="Sua mensagem"
            ></textarea>
          </div>

          <div className="form-group d-flex flex-column align-items-start">
            <label for="endereco">Endereço</label>
            <input
              type="text"
              name="endereco"
              id="endereco"
              className="form-control"
              placeholder="Endereço completo"
            />
          </div>

          <div className="form-group d-flex flex-column align-items-start">
            <label for="certidao">Tipo de Certidão</label>
            <select name="certidao" id="certidao" className="form-control">
              <option value="Certidão de Penhor">Certidão de Penhor</option>
              <option value="Certidão de ações reipercusórias">
                Certidão de ações reipercusórias
              </option>
              <option value="Certidão de ônus">Certidão de ônus</option>
              <option value="Certidão de inteiro teor">
                Certidão de inteiro teor
              </option>
              <option value="Outros">Outros</option>
            </select>
          </div>

          <div className="form-group d-flex flex-column align-items-start">
            <label for="solicitacao">Sua solicitação</label>
            <textarea
              name="solicitacao"
              id="solicitacao"
              className="form-control autosize"
              placeholder="Solicitacao"
            ></textarea>
          </div>

          <div className="form-group d-flex flex-column align-items-start">
            <input type="hidden" name="hack" placeholder="anti-spam" />
            <button type="submit" className="btn btn-dark">
              <i className="fa fa-rocket"></i> ENVIAR PEDIDO
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
