import React from "react";
import { Link } from "react-router-dom";

export const OqueRegistrarRtd = () => {
  return (
    <div className="margin-top">
      <div className="page-header-ri-background-modeloReq"></div>
      <div className="page-header-ri">
        <h5>Registro de Títulos de Documentos</h5>
        <span className="subtitle">O que registrar?</span>
      </div>
      <div className="container mt-3 d-flex flex-column align-items-start quem-somos">
        <h5>O que podemos registrar:&nbsp;</h5>
        <table className="fs-6-5 tipos-servicos w-100">
          <tbody>
            <tr>
              <td>Acordo</td>
              <td>Alienação Fiduciária</td>
              <td>Arrendamento de Terra</td>
            </tr>
            <tr>
              <td>Atestado</td>
              <td>Autorização</td>
              <td>Boletim de Ocorrência</td>
            </tr>
            <tr>
              <td>Bula</td>
              <td>Carta</td>
              <td>Carteira Profissional</td>
            </tr>
            <tr>
              <td>Caução</td>
              <td>Colheita Futura</td>
              <td>Compra e Venda</td>
            </tr>
            <tr>
              <td>Confissão de Dívida</td>
              <td>Contrato de Empreitada</td>
              <td>Contrato de Estágio</td>
            </tr>
            <tr>
              <td>Contrato de Honorários</td>
              <td>Contrato de Trabalho</td>
              <td>Declaração</td>
            </tr>
            <tr>
              <td>Descritivo</td>
              <td>Diploma</td>
              <td>Laudo Memorial</td>
            </tr>
            <tr>
              <td>Locação de Imóvel</td>
              <td>Nota Promissória</td>
              <td>Opção de venda/Locação</td>
            </tr>
            <tr>
              <td>Orçamento</td>
              <td>Parecer</td>
              <td>Pedido</td>
            </tr>
            <tr>
              <td>Permuta</td>
              <td>Prestação de Contas</td>
              <td>Promessa de Cessão</td>
            </tr>
            <tr>
              <td>Quitação</td>
              <td>Recibo</td>
              <td>Regulamento</td>
            </tr>
            <tr>
              <td>Requerimentos Doação</td>
              <td>Tradução</td>
              <td>Utilização de Know-how</td>
            </tr>
            <tr>
              <td>
                <strong>entre outros...</strong>
              </td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        </table>
        <p>
          <br />
        </p>
        <h5>
          Acesse nossos modelos de requerimentos, clicando{" "}
          <Link to="/modelos-requerimentos-pj/">aqui</Link>.
        </h5>
        <p>
          <br />
        </p>
        <h5>Registro de Ata de eleição/nomeação de Síndico (a):</h5>
        <p>
          Clique{" "}
          <Link to="" target="_blank">
            aqui
          </Link>{" "}
          para ter acesso aos documentos exigidos para esse tipo registro.
        </p>
      </div>
    </div>
  );
};
