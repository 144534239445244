import React, { useState, useEffect } from "react";
import api from "../utils/api.utils";

import loadingGif from "../imgs/loading-state.gif";

import { MsgError, MsgSucess } from "../components/Shared";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";

const LgpdForm = ({ message, setMessage }) => {
  const [formData, setFormData] = useState({
    nome: "",
    documento: "",
    email: "",
    telefone: "",
    solicitante: "",
    finalidade: "",
    informacao: "",
    termo: false,
  });

  const navigate = useNavigate();

  const [showEnviar, setShowEnviar] = useState(false);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleChangeTermo = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: type === "checkbox" ? checked : value,
    }));
    setShowEnviar(!showEnviar);
  };
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const showMessage = (error) => {
    setError(error);
    setTimeout(() => {
      setError("");
    }, 6000);
  };
  useEffect(() => {
    setTimeout(() => {
      setMessage(null);
    }, 60000);
  }, [message, setMessage]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    // Handle form submission here
    const {
      nome,
      documento,
      email,
      telefone,
      solicitante,
      finalidade,
      informacao,
      termo,
    } = formData;

    if (
      nome &&
      email &&
      documento &&
      telefone &&
      solicitante &&
      finalidade &&
      informacao &&
      termo
    ) {
      try {
        console.log(termo);
        setMessage(null);
        setLoading(true);
        setError(null);
        await api.sendLGPD({ formData });
        setMessage("Obrigado! Sua mensagem foi enviada!");
        setLoading(false);
        setFormData({
          nome: "",
          documento: "",
          email: "",
          telefone: "",
          solicitante: "",
          finalidade: "",
          informacao: "",
          termo: false,
        });
        navigate("/solicitacao-dados/");
      } catch (error) {
        showMessage(error);
      }

      console.log(formData);
    } else {
      setError("Todos os campos com * são obrigatórios!");
    }
  };

  return (
    <div className="margin-top">
      <div className="page-header-ri-background-legislacao"></div>
      <div className="page-header-ri">
        <span>Formulário para Exercício de Direitos</span>
      </div>
      <div className="container mt-3 d-flex  fs-6-5 quem-somos">
        <form id="lgpdForm" onSubmit={handleSubmit}>
          <p>
            De acordo com a Lei Geral de Proteção de Dados Pessoais (LGPD) – Lei
            13.709/2018, disponibilizamos o formulário abaixo para que o
            requerente fique ciente que para exercer o seu direito de acesso à
            informação, os dados do titular estão protegidos e por este motivo o
            requerente está sujeito à responsabilidade civil e criminal pela
            utilização indevida ou ilícita dos dados obtidos perante a
            Serventia:
          </p>
          <p style={{ color: "red" }}>
            Os campos marcados com * são de preenchimento obrigatório.
          </p>
          <div className="form-group d-flex flex-column align-items-start">
            <label htmlFor="nome">Nome completo:*</label>
            <input
              type="text"
              name="nome"
              className="form-control"
              onChange={handleChange}
            />
          </div>
          <div className="form-group d-flex flex-column align-items-start">
            <label htmlFor="documento">RG e CPF:*</label>
            <input
              type="text"
              name="documento"
              className="form-control"
              onChange={handleChange}
            />
          </div>
          <div className="form-group d-flex flex-column align-items-start">
            <label htmlFor="email">E-mail:*</label>
            <input
              type="email"
              name="email"
              className="form-control"
              onChange={handleChange}
            />
          </div>
          <div className="form-group d-flex flex-column align-items-start">
            <label htmlFor="telefone">DDD e Telefone celular:*</label>
            <input
              type="number"
              name="telefone"
              className="form-control"
              onChange={handleChange}
            />
          </div>
          <br className="espacamento-03" />
          <div className="form-group d-flex flex-column align-items-start">
            <strong>Quem solicita é?</strong>
            <div className="form-check">
              <input
                type="radio"
                value="Cliente"
                name="solicitante"
                className="form-check-input"
                onChange={handleChange}
              />
              <label htmlFor="cliente" className="form-check-label">
                Cliente/Usuário
              </label>
            </div>
            <div className="form-check">
              <input
                type="radio"
                value="Colaborador"
                name="solicitante"
                className="form-check-input"
                onChange={handleChange}
              />
              <label htmlFor="colaborador" className="form-check-label">
                Colaborador
              </label>
            </div>
            <div className="form-check">
              <input
                type="radio"
                value="ExColaborador"
                name="solicitante"
                className="form-check-input"
                onChange={handleChange}
              />
              <label htmlFor="exColaborador" className="form-check-label">
                Ex Colaborador
              </label>
            </div>
            <div className="form-check">
              <input
                type="radio"
                value="Fornecedor"
                name="solicitante"
                className="form-check-input"
                onChange={handleChange}
              />
              <label htmlFor="fornecedor" className="form-check-label">
                Fornecedor/Prestador de Serviços
              </label>
            </div>
            <div className="form-check">
              <input
                type="radio"
                value="Outro"
                name="solicitante"
                className="form-check-input"
                onChange={handleChange}
              />
              <label htmlFor="outro" className="form-check-label">
                Outro
              </label>
            </div>
          </div>
          <br className="espacamento-03" />
          <div className="form-group d-flex flex-column align-items-start">
            <label htmlFor="finalidade">
              <strong>*Descrição da solicitação e finalidade:</strong>
            </label>
            <input
              type="text"
              name="finalidade"
              className="form-control"
              onChange={handleChange}
            />
          </div>
          <div className="form-group d-flex flex-column align-items-start mt-3">
            <strong>
              *Em razão da Lei Geral de Proteção de Dados Pessoais (LGPD) o
              requerente solicita que os dados acima sejam informados da
              seguinte forma:
            </strong>
            <div className="form-check">
              <input
                type="radio"
                value="ExColaborador"
                name="informacao"
                className="form-check-input"
                onChange={handleChange}
              />
              <label htmlFor="exColaborador" className="form-check-label">
                Confirmação de existência de tratamento
              </label>
            </div>
            <div className="form-check">
              <input
                type="radio"
                value="acesso"
                name="informacao"
                className="form-check-input"
                onChange={handleChange}
              />
              <label htmlFor="acesso" className="form-check-label">
                Acesso completo aos dados
              </label>
            </div>
            <div className="form-check">
              <input
                type="radio"
                value="correcao"
                name="informacao"
                className="form-check-input"
                onChange={handleChange}
              />
              <label htmlFor="correcao" className="form-check-label">
                Correção ou atualização dos dados
              </label>
            </div>
            <div className="form-check">
              <input
                type="radio"
                value="anonimizacao"
                name="informacao"
                className="form-check-input"
                onChange={handleChange}
              />
              <label htmlFor="anonimizacao" className="form-check-label">
                Anonimização, bloqueio ou eliminação de dados tratados em
                desconformidade com a lei
              </label>
            </div>
            <div className="form-check">
              <input
                type="radio"
                value="vedacao"
                name="informacao"
                className="form-check-input"
                onChange={handleChange}
              />
              <label htmlFor="vedacao" className="form-check-label">
                Vedação de dados pessoais
              </label>
            </div>
          </div>
          <br className="espacamento-03" />
          <div className="form-check d-flex">
            <input
              type="checkbox"
              name="termo"
              className="form-check-input"
              checked={formData.termo}
              onChange={handleChangeTermo}
            />
            <label htmlFor="termo" className="form-check-label">
              Ao marcar esta caixa e enviar o formulário, você está de acordo
              com o tratamento dos dados acima informados, nos termos do nosso{" "}
              <Link
                to="https://iuryflores.com.br/riscgo/Poli%CC%81tica%20de%20Privacidade-%20Final.pdf"
                target="_blank"
              >
                <u>Aviso de Privacidade</u>
              </Link>{" "}
              disponibilizado no site.
            </label>
          </div>
          <br className="espacamento-03" />
          {showEnviar ? (
            <div className="form-group mt-3 ">
              {!loading ? (
                <button type="submit" className="btn btn-primary">
                  <i className="fa fa-rocket"></i> ENVIAR
                </button>
              ) : (
                <div>
                  <img
                    style={{ width: "40px" }}
                    src={loadingGif}
                    alt="Loading gif"
                  />
                  Enviando...
                </div>
              )}
            </div>
          ) : (
            <div className="alert alert-danger">
              É necessário aceitar os termos do nosso Aviso de Privacidade.
            </div>
          )}

          <div className="form-group d-flex flex-column align-items-start mt-3">
            <div className="mt-3">
              {error && <MsgError id="erroID">{error}</MsgError>}
              {message && <MsgSucess id="messageID">{message}</MsgSucess>}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LgpdForm;
