import React from "react";
import { Link } from "react-router-dom";

export const OqueRegistrarPj = () => {
  return (
    <div className="margin-top">
      <div className="page-header-ri-background-modeloReq"></div>
      <div className="page-header-ri">
        <h4>Registro de Pessoa Jurídica</h4>
        <span className="subtitle">O que registrar?</span>
      </div>
      <div className="container mt-3 d-flex flex-column align-items-start quem-somos">
        <h4>REGISTRO DE PESSOAS JURÍDICAS:</h4>
        <p></p>
        <p>
          <strong>REGISTRO</strong>
        </p>
        <p>
          •{" "}
          <Link to="" target="_blank">
            REGISTRO DE FUNDAÇÃO / CONSTITUIÇÃO
          </Link>
          &nbsp;
        </p>
        <p>
          •{" "}
          <Link to="" target="_blank">
            TRANSFERÊNCIA DE SEDE DE OUTRO MUNICÍPIO PARA SENADOR CANEDO
          </Link>
        </p>
        <p>
          <Link to=""></Link>•{" "}
          <Link to="" target="_blank">
            INSCRIÇÃO DE FILIAL
          </Link>
        </p>
        
        <p>
          <strong data-verified="redactor">AVERBAÇÃO</strong>&nbsp;
        </p>
        <p>
          •{" "}
          <Link to="" target="_blank">
            AVERBAÇÃO DE PESSOA JURÍDICA
          </Link>
        </p>
        <p>
          •{" "}
          <Link to="" target="_blank">
            AVERBAÇÃO DE ALTERAÇÃO (ELEIÇÃO/ NOMEAÇÃO) DE MEMBROS
          </Link>
        </p>
        <p>
          •{" "}
          <Link to="" target="_blank">
            AVERBAÇÃO DE ALTERAÇÃO ESTATUTÁRIA
          </Link>
        </p>
        <p>
          •{" "}
          <Link to="" target="_blank">
            REATIVAÇÃO
          </Link>
        </p>
        <p>
          •{" "}
          <Link to="" target="_blank">
            EXTINÇÃO/ DISSOLUÇÃO
          </Link>
        </p>
        <p>
          <br />
        </p>
        <h4>REGISTRO DE SOCIEDADE SIMPLES E SIMPLES LTDA:</h4>
        <p></p>
        <p>
          <strong>REGISTRO</strong>
        </p>
        <p>
          •{" "}
          <Link
            to="http://ritdsenadorcanedo.com.br/rtd/registro-de-pessoas-juridicas/sociedade-simples-e-simples-ltda"
            target="_blank"
          >
            SOCIEDADE SIMPLES E SIMPLES LTDA
          </Link>
        </p>
        <p>
          •{" "}
          <Link
            to="http://ritdsenadorcanedo.com.br/rtd/registro-de-pessoas-juridicas/transferencia-de-sede-de-sociedade-para-senador-canedo"
            target="_blank"
          >
            TRANSFERÊNCIA DE SEDE DE OUTRO MUNICÍPIO PARA SENADOR CANEDO
          </Link>
        </p>

        <p>
          <strong data-verified="redactor">AVERBAÇÃO</strong>
        </p>
        <p>
          •{" "}
          <Link
            to="http://ritdsenadorcanedo.com.br/rtd/registro-de-pessoas-juridicas/distrato-de-sociedade-simples-sociedade-simples-ltda-eireli"
            target="_blank"
          >
            DISTRATO DE SOCIEDADE SIMPLES / SOCIEDADE SIMPLES LTDA / EIRELI
          </Link>
        </p>{" "}
      </div>
    </div>
  );
};
