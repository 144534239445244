import React from "react";
import { CarouselComp } from "../components/Carousel";
import { DestaquesComp } from "../components/Destaques";
import { ServicosOnline } from "../components/ServicosOnline";

export const Home = () => {
  return (
    <div>
      <CarouselComp />
      <ServicosOnline />
      <DestaquesComp />
    </div>
  );
};
